import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Route, Router } from '@angular/router';
import { FusejsModule } from 'angular-fusejs';
import { AppComponent } from './app.component';
import { CommandPaletteComponent } from './components/command-palette/command-palette.component';
import { CultureSelectorComponent } from './components/culture-selector/culture-selector.component';
import { DragComponent } from './components/drag/drag.component';
import { EasterEggComponent, EasterEggDialogComponent } from './components/easter-egg/easter-egg.component';
import { MonacoEditorModule } from './components/editor';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { HeaderComponent } from './components/header/header.component';
import { JsonFormatterComponent } from './components/json-formatter/json-formatter.component';
import { MarkdownHtmlComponent } from './components/markdown-html/markdown-html.component';
import { ServiceTesterInfoComponent } from './components/service-tester-info/service-tester-info.component';
import { FilePickerComponent } from './components/service-tester/file-picker/file-picker.component';
import { ServiceTesterComponent } from './components/service-tester/service-tester.component';
import { TesterComponent } from './components/tester/tester.component';
import { EvaContextMenuModule } from './modules/context-menu/context-menu.module';
import { EndPointSelectionModule } from './modules/endpoint-selection/endpoint-selection.module';
import { LoginModule } from './modules/login/login.module';
import { UiEditorModule } from './modules/ui-editor/ui-editor.module';
import { LandingComponent } from './pages/landing/landing.component';
import { ServiceDetailsComponent } from './pages/service-details/service-details.component';
import { ServicesSelectorComponent } from './pages/services-selector/services-selector.component';
import { TesterContainerComponent } from './pages/tester-container/tester-container.component';
import { ApplicationsService } from './services/applications.service';
import { CanActivateTesterService } from './services/can-activate.service';
import { ClipboardService } from './services/clipboard.service';
import { CommandPaletteService } from './services/command-palette.service';
import { EndPointUrlService } from './services/end-point-url.service';
import { EvaTypingsService } from './services/eva-typings.service';
import { ListServicesService } from './services/list-services.service';
import { ServiceSelectorService } from './services/service-selector.service';
import { StoreInitService } from './services/store-init.service';
import { ThemeService } from './services/theme.service';
import { tokenInterceptor } from './services/token-interceptor';
import { UsernameCacheService } from './services/username-cache.service';
import { PipesModule } from './modules/pipes/pipes.module';
import { TesterStatusBarComponent } from './components/tester-status-bar/tester-status-bar.component';
import { DirectivesModule } from './modules/directives/directives.module';
import { DocumentationComponent } from './pages/documentation/documentation.component';
import { ConfirmAlertModule } from './shared/confirm-alert/confirm-alert.module';
import * as Sentry from "@sentry/angular";
import { ServiceSecurityComponent } from './components/service-security/service-security.component';
import { LocalizedDatePipe } from './shared/pipes/localized-date.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

const routes: Route[] = [
  { path: 'landing', component: LandingComponent },
  { path: 'documentation', component: DocumentationComponent },
  {
    path: '',
    canActivate: [CanActivateTesterService],
    children: [
      { path: '', component: TesterContainerComponent },
      { path: ':serviceName', component: TesterContainerComponent }]
  },
  { path: 'service/:serviceName', redirectTo: ':serviceName' },
  { path: 'selector/:portNumber', component: ServicesSelectorComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    TesterComponent,
    HeaderComponent,
    ServicesSelectorComponent,
    ServiceDetailsComponent,
    CommandPaletteComponent,
    ServiceTesterComponent,
    MarkdownHtmlComponent,
    EasterEggComponent,
    EasterEggDialogComponent,
    JsonFormatterComponent,
    CultureSelectorComponent,
    EmptyStateComponent,
    DragComponent,
    ServiceTesterInfoComponent,
    TesterContainerComponent,
    DocumentationComponent,
    FilePickerComponent,
    TesterStatusBarComponent,
    LandingComponent,
    ServiceSecurityComponent,
    LocalizedDatePipe
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    MonacoEditorModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    FusejsModule,
    MatChipsModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    EvaContextMenuModule,
    LoginModule.forRoot(),
    EndPointSelectionModule,
    MatListModule,
    MatDividerModule,
    UiEditorModule,
    PipesModule,
    DirectivesModule,
    ConfirmAlertModule,
    MatTableModule,
    MatPaginatorModule,
  ],
  entryComponents: [EasterEggDialogComponent],
  providers: [
    tokenInterceptor,
    EvaTypingsService,
    ListServicesService,
    ApplicationsService,
    ServiceSelectorService,
    EndPointUrlService,
    StoreInitService,
    ClipboardService,
    ThemeService,
    CommandPaletteService,
    UsernameCacheService,
    CanActivateTesterService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
