import { Component, Input, OnInit } from '@angular/core';
import { listAnimation } from '../../shared/animations';
import { ITesterState } from '../tester/tester.component';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export type TTesterInfo = Pick<ITesterState, 'listMetaData' | 'detailMetaData'>;

@Component({
  selector: 'eva-service-tester-info',
  templateUrl: './service-tester-info.component.html',
  styleUrls: ['./service-tester-info.component.scss'],
  animations: [listAnimation]
})
export class ServiceTesterInfoComponent implements OnInit {

  @Input() testerInfo: TTesterInfo;

  constructor(
    private $clipboardService: ClipboardService,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  copyServiceUrlToClipBoard() {
    const serviceName = this.testerInfo.listMetaData.Name;

    const url = window.location.origin + '/' + serviceName;

    this.$clipboardService.copyToClipboard( url );

    this.matSnackBar.open('Share url copied to clipboard', null, { duration: 3000 });
  }

}
