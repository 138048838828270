import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmAlertService } from './confirm-alert.service';

@NgModule({
  imports: [
    MatButtonModule,
    MatDialogModule
  ],
  declarations: [ConfirmDialogComponent],
  entryComponents: [ConfirmDialogComponent],
  providers: [ConfirmAlertService]
})
export class ConfirmAlertModule { }
