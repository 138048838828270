<mat-form-field *ngIf="isBitFlagEnum">
  <mat-label>{{field.Name}}</mat-label>
  <mat-select [placeholder]="field.Name" multiple [formControl]="mainControl">
    <mat-option [value]="undefined">-</mat-option>
    <mat-option *ngFor="let enumField of enumFields" [value]="enumField.value">{{ enumField.label }} ({{enumField.value}})</mat-option>
  </mat-select>
  <mat-hint class="d-flex align-items-stretch" *ngIf="field.Deprecation">
    <mat-icon class="warning mr-1">warning</mat-icon>
    <span [matTooltip]="field.Deprecation">This field is deprecated, hover for more info</span>
  </mat-hint>
  <mat-hint class="d-flex align-items-stretch" *ngIf="field.Description && !field.Deprecation">
    <mat-icon class="mr-1">info</mat-icon>
    <span [matTooltip]="field.Description">Hover for field description</span>
  </mat-hint>
</mat-form-field>
<mat-form-field *ngIf="!isBitFlagEnum">
  <mat-label>{{field.Name}}</mat-label>
  <mat-select [placeholder]="field.Name" [formControl]="mainControl">
    <mat-option [value]="undefined">-</mat-option>
    <mat-option *ngFor="let enumField of enumFields" [value]="enumField.value">{{ enumField.label }} ({{enumField.value}})</mat-option>
  </mat-select>
  <mat-hint class="d-flex align-items-stretch" *ngIf="field.Deprecation">
    <mat-icon class="warning mr-1">warning</mat-icon>
    <span [matTooltip]="field.Deprecation">This field is deprecated, hover for more info</span>
  </mat-hint>
  <mat-hint class="d-flex align-items-stretch" *ngIf="field.Description && !field.Deprecation">
    <mat-icon class="mr-1">info</mat-icon>
    <span [matTooltip]="field.Description">Hover for field description</span>
  </mat-hint>
</mat-form-field>
