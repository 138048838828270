import { Component } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { BaseEnvironment } from '@springtree/eva-config-picker';
import { EndPointUrlService, EndPointValidation } from 'src/app/services/end-point-url.service';

@Component({
  selector: 'eva-endpoint-selector',
  templateUrl: './endpoint-selector.component.html',
  styleUrls: ['./endpoint-selector.component.scss']
})
export class EndpointSelectorComponent {


  defaultEndPoint = this.$endPointUrlService.DEFAULT_ENDPOINT_URL;


  endPointFormControl = new FormControl(
    this.defaultEndPoint,
    EndpointSelectorComponent.urlValidator
  );

  customEndPointValidation = {
    customEndPointValidationFeedback: null,
    customEndPointValidationLoading: false
  };

  static urlValidator = (ac): ValidationErrors => {
    if (!ac.touched) {
      return null;
    }
    try {
      const _url = new URL(ac.value);
      return null;
    } catch (error) {
      return { invalidUrl: true };
    }
  }

  constructor(private $endPointUrlService: EndPointUrlService) { }

  async endPointSelected(endPointUrl: string) {

    this.customEndPointValidation.customEndPointValidationLoading = true;

    const validateEndPointOutput = await this.$endPointUrlService.validateEndPoint(endPointUrl);

    if (validateEndPointOutput === EndPointValidation.valid) {
      this.customEndPointValidation.customEndPointValidationFeedback = 'This endpoint is correct✅ and its online 🎉';
    } else if (validateEndPointOutput === EndPointValidation.invalid) {
      this.customEndPointValidation.customEndPointValidationFeedback = 'This endpoint is invalid👎, are you sure you want to proceed?';
    } else if (validateEndPointOutput === EndPointValidation.down) {
      this.customEndPointValidation.customEndPointValidationFeedback = 'This endpoint is correct✅ but its offline 😕, proceed?';
    }

    this.customEndPointValidation.customEndPointValidationLoading = false;
  }

  configPickerEndPointSelected(configPickerEvent: CustomEvent<BaseEnvironment>) {
    this.setEndpoint(configPickerEvent.detail.endpoint);
  }

  setEndpoint(endPointUrl: string) {
    this.$endPointUrlService.endPointUrl = endPointUrl;

    this.$endPointUrlService.onChange(endPointUrl);
  }
}
