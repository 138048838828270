<div [hidden]="showLoginAgainUserInterface$ | async">
  <ng-container *ngIf="selectedServices?.length > 0">
    <div class="tabs-container">
      <mat-tab-group class="services-tabs" [(selectedIndex)]="selectedTabIndex">
        <mat-tab *ngFor="let selectedService of selectedServices; let index = index"
          [label]="selectedService.tabName || 'Service ' + (index + 1) ">
          <eva-service-tester-info [testerInfo]="selectedServices[index]"></eva-service-tester-info>
        </mat-tab>
      </mat-tab-group>

      <button mat-button (click)="addTab()" class="add-button">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <eva-service-tester [hidden]="!selectedServices[selectedTabIndex]?.listMetaData"
      [testerState]="selectedServices[selectedTabIndex]"
      (editorContainerStateChange)="updateServicesState($event, selectedTabIndex)"></eva-service-tester>
  </ng-container>


  <eva-empty-state *ngIf="!selectedServices[selectedTabIndex]?.listMetaData" title="Select a service to get started"
    icon="list">
  </eva-empty-state>

</div>
<eva-empty-state *ngIf="showLoginAgainUserInterface$ | async"
  title="Your session timed out, please login to continue"
  icon="error">

  <div class="d-flex justify-content-center mt-3">
    <button mat-button (click)="openLoginModal()">
      Login in EVA
    </button>
    <button mat-button color="warn" (click)="forceHideLoginAgainUserInterface()">
      Use anonymous services
    </button>
  </div>
</eva-empty-state>
