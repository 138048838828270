import { Component, forwardRef, Inject, Input, NgZone, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


import { BaseEditor } from './base-editor';
import { NGX_MONACO_EDITOR_CONFIG, NgxMonacoEditorConfig } from './config';
import { NgxEditorModel } from './types';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'eva-monaco-editor',
  template: '<div class="editor-container" #editorContainer></div>',
  styles: [`
    :host {
      display: block;
      height: 200px;
    }

    .editor-container {
      width: 100%;
      height: 98%;
    }
  `],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditorComponent),
    multi: true
  }]
})
export class EditorComponent extends BaseEditor<monaco.editor.IStandaloneCodeEditor> implements ControlValueAccessor {

  @Output() monacoLoad = new EventEmitter<void>();

  /** We will emit  */
  @Output() monacoPaste = new EventEmitter<monaco.editor.ICursorSelectionChangedEvent>();

  private _value = '';

  propagateChange = (_: any) => {};

  onTouched = () => {};

  _model: NgxEditorModel;

  get model(): NgxEditorModel {
    return this._model;
  }

  @Input('model')
  set model(model: NgxEditorModel) {
    this._model = model;
    if (this.options) {
      this.options.model = model;
    } else {
      console.warn('[editor] model set before options object was available');
    }
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(this.options);
    }
  }

  constructor(private zone: NgZone, @Inject(NGX_MONACO_EDITOR_CONFIG) private editorConfig: NgxMonacoEditorConfig) {
    super(editorConfig);

    this.editorConfig.onMonacoLoad = () => this.monacoLoad.emit();
  }

  writeValue(value: any): void {
    this._value = value || '';
    // Fix for value change while dispose in process.
    setTimeout(() => {
      if (this._editor && !this.options.model) {
        this._editor.setValue(this._value);
      }
    });
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  protected initMonaco(options: any): void {

    if (this.model) {
      options.model = this.model;
    }

    const hasModel = !!options.model;

    if (hasModel) {
      const modelUri = monaco.Uri.file(options.model.uri);
      const monacoModel = monaco.editor.getModel(modelUri);

      if (monacoModel) {
        monacoModel.dispose();
      }

      options.model = monaco.editor.createModel(options.model.value, options.model.language, modelUri);
    }

    this._editor = monaco.editor.create(this._editorContainer.nativeElement, options);

    this._editor.setValue(this._value);

    this._editor.onDidChangeModelContent((e: any) => {
      const value = this._editor.getValue();
      this.propagateChange(value);
      // value is not propagated to parent when executing outside zone.
      this.zone.run(() => this._value = value);
    });

    this._editor.onDidBlurEditorText(() => {
      this.onTouched();
    });

    // refresh layout on resize event.
    if (this._windowResizeSubscription) {
      this._windowResizeSubscription.unsubscribe();
    }
    this._windowResizeSubscription = fromEvent(window, 'resize').subscribe(() => this._editor.layout());
    this.onInit.emit(this._editor);

    this._editor.onDidChangeCursorSelection( event => {
      if ( event.reason === monaco.editor.CursorChangeReason.Paste ) {
        console.log('paste..', event);
        console.log('paste..', this._editor.getValue());
        this.monacoPaste.emit(event);
      }
    })
  }

}
