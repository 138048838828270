<div *ngIf="userLoaded$ | async">
  <eva-login-card *ngIf="!(loggedIn$ | async)" @fadeInOut></eva-login-card>

  <div class="d-flex justify-content-center align-items-center my-2" *ngIf="(loggedIn$ | async)" @fadeInOut>
    <form [formGroup]="searchForm" (ngSubmit)="createServices(servicesForm.value)">
      <mat-form-field>
        <input matInput type="search" placeholder="Search service" formControlName="search">
      </mat-form-field>
    </form>

    <button mat-button [disabled]="!reducerIsSelected" (click)="createServices(servicesForm.value)">Create
      reducer</button>
    <mat-spinner diameter=25 *ngIf="loading$ | async"></mat-spinner>
  </div>

  <div *ngIf="(loggedIn$ | async)" @fadeInOut>
    <div class="d-flex justify-content-center" *ngIf=" services$ | async; let services">
      <form [formGroup]="servicesForm">
        <mat-card *ngFor="let service of (services | fusejs:searchTerms:searchOptions)"
          (click)="programmaticallySelectFormControl(service.Type)">
          <mat-card-title class="d-flex justify-content-between" [attr.title]="service.Name">
            <span [innerHTML]="service.fuseJsHighlighted.Name"></span>
            <mat-checkbox [formControlName]="service.Type" labelPosition="before" (click)="stopPropagation($event)">
            </mat-checkbox>
          </mat-card-title>
          <mat-card-subtitle [attr.title]="service.Type">{{ service.Type }}</mat-card-subtitle>

        </mat-card>
      </form>

      <mat-card class="warning">
        <mat-card-title class="d-flex align-items-center justify-content-between">
          <span>Warning</span>
          <mat-icon>warning</mat-icon>
        </mat-card-title>
        <mat-card-content>
          The listed services depend on your current environment <code>{{ currentEndPoint }}</code> and user privileges.
          So
          not ALL services are <b>always</b> on this list
        </mat-card-content>
      </mat-card>
    </div>

  </div>
</div>
