import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { getCurrentUser, settings } from '@springtree/eva-sdk-redux';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommandPaletteService } from 'src/app/services/command-palette.service';
import { EndPointUrlService } from 'src/app/services/end-point-url.service';
import isNotNil from 'src/app/shared/operators/is-not-nil';
import { EndpointSelectorModalComponent } from '../../modules/endpoint-selection/endpoint-selector-modal/endpoint-selector-modal.component';
import { CurrentAction } from '../command-palette/command-palette.component';
import { fadeInOut } from 'src/app/shared/animations';
import { DomSanitizer } from '@angular/platform-browser';

export interface EndpointInfoResponse {
  Namespace: string;
  DeploymentStage: number;
  IsLocalDevelopment: boolean;
  IsTest: boolean;
  IsTestSuite: boolean;
  IsProduction: boolean;
  CommitID: string;
  Version: string;
  MachineName: string;
  BuildTime: Date;
  StartTime: Date;
  MaxWorkerThreads: number;
  MaxIoThreads: number;
  BusyIoThreads: number;
  BusyWorkerThreads: number;
  InternetAvailable: boolean;
  EVACloudAvailable: boolean;
  RunningAsSentinel: boolean;
  RunningInSentinelOpsMode: boolean;
}

interface EnvironmentButton {
  text: string;
  icon: string;
  cssClass?: string;
}

@Component({
  selector: 'eva-tester-status-bar',
  templateUrl: './tester-status-bar.component.html',
  styleUrls: ['./tester-status-bar.component.scss'],
  animations: [fadeInOut]
})
export class TesterStatusBarComponent implements OnInit {

  public currentEndpoint: string = this.$endPointUrl.endPointUrl;

  private readonly endpointInfoPath = this.currentEndpoint + '/info';

  public endPointInfo$ = this.http.get<EndpointInfoResponse>(this.endpointInfoPath);

  public endpointInfoVersion$ = this.endPointInfo$.pipe(
    map( e => e.Version)
  );

  namespaceTooltip$ = this.endPointInfo$.pipe(
    map( e => `endpoint of current environment (${e.Namespace})`)
  );

  versionTooltip$ = this.endPointInfo$.pipe(
    map( e => `version of current environment (${e.Namespace})`)
  );

  public environmentModeButton$: Observable<EnvironmentButton> = this.endPointInfo$.pipe(
    map(endpointInfo => {

      const button: EnvironmentButton = {
        icon: 'public',
        text: 'Test environment'
      };

      if (endpointInfo.IsProduction) {
        button.icon = 'warning';
        button.text = 'Production environment';
        button.cssClass = 'warning';
      }

      if ( endpointInfo.IsLocalDevelopment ) {
        button.text += ' (Local)';
      }

      return button;
    })
  );

  public currentOrganizationName$ = getCurrentUser.getResponse$().pipe(
    isNotNil(),
    map(response => {
      const isEmployee = Boolean(response.User.Type & 1);

      return isEmployee ? response.User.CurrentOrganizationName : null;
    }),
  );

  public currentUserName$ = getCurrentUser.getResponse$().pipe(
    isNotNil(),
    map(response => {
      const isEmployee = Boolean(response.User.Type & 1);

      return isEmployee ? response.User.FullName : null;
    })
  );

  public userImage$ = getCurrentUser.getResponse$().pipe(
    isNotNil(),
    map( response => {
      const gravatarHash = response.User.GravatarHash;


      const imageUrl = `https://www.gravatar.com/avatar/${gravatarHash}`;

      return this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
    })
  );

  constructor(
    private $endPointUrl: EndPointUrlService,
    private http: HttpClient,
    private commandPaletteService: CommandPaletteService,
    private matDialog: MatDialog,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  openCommandPallete() {
    this.commandPaletteService.open();
  }

  switchEndpoint() {
    const dialog = this.matDialog.open(EndpointSelectorModalComponent);
  }

  openEndpointInfo() {
    window.open(this.endpointInfoPath);
  }

  changeOrganization() {
    this.commandPaletteService.open(CurrentAction.OrganizationsSearch);
  }

  openSlack() {
    const TEAM_ID = 'T044PAFBD';
    const CHANNEL_ID = 'C01B4MQ1K46';

    window.open(`slack://channel?team=${TEAM_ID}&id=${CHANNEL_ID}`);
  }

}
