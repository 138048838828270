import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getCurrentUser, settings, store } from '@springtree/eva-sdk-redux';
import { isNil, isEmpty, noop } from 'lodash-es';
import { Observable } from 'rxjs';
import { EndPointUrlService } from './end-point-url.service';
import { Logger, ILoggable } from '../decorators/logger';
import { tap } from 'rxjs/operators';

@Logger
@Injectable()
export class TokenInterceptor implements HttpInterceptor, ILoggable {

  logger: Partial<Console>;

  constructor(private $endPointUrl: EndPointUrlService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.logger.log(`userToken is ${settings.userToken} defaultToken is ${settings.defaultToken}`);

    const token = isEmpty(settings.userToken) ? settings.defaultToken : settings.userToken;

    if (!isEmpty(token)) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          authorization: token
        }
      });
    }

    // This is how we will determine this is an eva message, and we will ensure we add the required EVA-User-Agent header
    //
    request = request.clone({
      setHeaders: {
        'EVA-User-Agent': 'dora'
      }
    });

    const response = next.handle(request).pipe(
      tap(noop, error => {
        // If a 401 occurs in any of the HTTP requests we perform to eva, we will re-fetch the `getCurrentUser` to
        // ensure the expired$ is triggered, this will make sure the UI gets updated correctly.
        //
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
          //  If the request url contains the current endpoint url, we know this is a call to eva
          const isEvaAPICall = error.url.includes(this.$endPointUrl.endPointUrl);

          if (isEvaAPICall) {
            const [action] = getCurrentUser.createFetchAction();

            store.dispatch(action);
          }
        }
      })
    );

    return response;
  }
}

export const tokenInterceptor = {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
};
