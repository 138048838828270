import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { isFunction } from 'lodash-es';
import { EndPointUrlService } from './end-point-url.service';

@Injectable()
export class CanActivateTesterService implements CanActivate {

  constructor( private $endpointUrl: EndPointUrlService, private router: Router ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ){
    const canActivate = this.$endpointUrl.DEFAULT_ENDPOINT_URL !== this.$endpointUrl.endPointUrl || this.$endpointUrl.endPointUrlManuallySet;

    if ( !canActivate ) {
      this.router.navigate(['landing']);
    }

    return canActivate;

  }
}
