<ng-container *ngIf="!showOpenIdButtons">
  <svg width="91px" height="38px" viewBox="0 0 91 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Artboard-2" fill="#3C3C3B" fill-rule="nonzero">
        <g id="Group">
          <polygon id="Shape" points="0 38 37 38 33.1008969 29 0 29"></polygon>
          <polygon id="Shape" points="74.8652968 0 70.1347032 0 54 36.5612472 54 38 72.5 38 72.5 28.9443207 67.2625571 28.9443207 72.5 17.0957684 81.7922374 38 91 38 91 36.5612472"></polygon>
          <polygon id="Shape" points="0 23 31 23 27.0621622 14 0 14"></polygon>
          <polygon id="Shape" points="54.7732634 0 45.6304063 20.9042316 36.4036697 0 0 0 0 9.05567929 30.5321101 9.05567929 43.2817824 38 47.8951507 38 64 1.43875278 64 0"></polygon>
        </g>
      </g>
    </g>
  </svg>

  <form [formGroup]="form" (ngSubmit)="submit(form.value)">
    <div class="controls-container">

      <mat-form-field appearance="outline">
        <mat-label>Username</mat-label>
        <input matInput placeholder="Username" formControlName="Username">
        <button mat-icon-button matSuffix type="button" (click)="autoFillFields()">
          <mat-icon>vpn_key</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput placeholder="Password" type="password" formControlName="Password">
      </mat-form-field>
    </div>

    <div class="buttons-container">
      <button mat-raised-button type="button" [disabled]="(loginFetching$ | async)" (click)="toggleOpenIdButtonsVisibility()">
        Login with OpenID
      </button>
      <button mat-raised-button type="submit" color="primary" [disabled]="form.invalid || (loginFetching$ | async)">
        <ng-container *ngIf="loginFetching$ | async">
          <mat-spinner [diameter]=30></mat-spinner>
        </ng-container>

        <ng-container *ngIf="!(loginFetching$ | async)">
          Login
        </ng-container>
      </button>
    </div>
  </form>
</ng-container>


<div class="open-id-container" *ngIf="showOpenIdButtons">
  <ng-container *ngFor="let provider of openIdButtons$ | async">
    <button mat-raised-button (click)="openIdLogin(provider)">
      {{provider.Name}}
    </button>
  </ng-container>

  <hr>

  <button mat-raised-button color="primary" (click)="toggleOpenIdButtonsVisibility()" class="mt-2">
    <mat-icon>arrow_back</mat-icon> back
  </button>
</div>
