import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoginCardComponent } from './login-card/login-card.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { OpenIdAuthService } from './open-id-auth.service';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  declarations: [LoginCardComponent, LoginModalComponent],
  exports: [LoginCardComponent, LoginModalComponent],
  entryComponents: [LoginModalComponent],
})
export class LoginModule {
  public static forRoot() {
    return {
      ngModule: LoginModule,
      providers: [
        OpenIdAuthService
      ]
    };
  }
}
