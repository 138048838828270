<h6 class="d-flex align-items-center">
  <span>{{ field.Name }}</span>
  <mat-icon class="warning ml-2" [matTooltip]="field.Deprecation" *ngIf="field.Deprecation">warning</mat-icon>
  <mat-icon class="ml-2" [matTooltip]="field.Description" *ngIf="field.Description">info</mat-icon>
</h6>

<div class="main-container">
  <div class="line"></div>
  <div class="forms-container">
    <form [formGroup]="form">
      <ng-container *ngFor="let field of field.Fields">
        <div class="form-container align-items-center">
          <div class="plus-dots"></div>
          <eva-ui-editor-element [formControlName]="field.Name" [field]="field"></eva-ui-editor-element>
        </div>
      </ng-container>
    </form>
  </div>

</div>
