import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { getCurrentUser, login, store } from '@springtree/eva-sdk-redux';
import { isEmpty } from 'lodash-es';
import { filter, map, take } from 'rxjs/operators';
import { ILoggable, Logger } from './decorators/logger';
import { OpenIdAuthService } from './modules/login/open-id-auth.service';
import { ApplicationsService } from './services/applications.service';
import { EndPointUrlService } from './services/end-point-url.service';
import { StoreInitService } from './services/store-init.service';
import { GetLoginOrganizationUnitsForUserService } from './services/get-login-organization-units-for-user.service';
@Logger
@Component({
  selector: 'eva-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements ILoggable {

  public logger: Partial<Console>;

  constructor(
    private $applicationService: ApplicationsService,
    private storeInitService: StoreInitService,
    private activatedRoute: ActivatedRoute,
    private $endPointUrlService: EndPointUrlService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private snackbar: MatSnackBar,
    private $openIdAuth: OpenIdAuthService,
    private $getLoginOrganizationUnitsForUserService: GetLoginOrganizationUnitsForUserService
  ) {

    this.loadApplications();


    this.activatedRoute.queryParamMap
    .pipe(
      map((paramsAsMap: ParamMap) => paramsAsMap.get('endpoint') as string ),
      filter( endpoint => Boolean(endpoint) ),
      filter( (endPoint: string) => {

        try {
          const newEndpointUrl = new URL(endPoint);

          const differentEndPoint = newEndpointUrl.origin !== new URL(this.$endPointUrlService.endPointUrl).origin;

          return differentEndPoint;
        } catch (e) {
          this.matSnackBar.open('Invalid URL', null, { duration: 3000 });
          return false;
        }
      } )
    )
    .subscribe( (endpoint: string) => {
      this.logger.log(endpoint);
      this.$endPointUrlService.onChange(endpoint);
    });

    getCurrentUser.expired$.subscribe((date) => {
      this.logger.log('[getCurrentUser:expired$] expired', date);
    });
  }

  private async handleOpenIdLogin(loginData: Partial<EVA.Core.Login>) {
    const [action, loginPromise] = login.createFetchAction({
      ...loginData,
      SelectFirstOrganizationUnit: true
    });
    store.dispatch(action);

    try {
      const loginResponse = await loginPromise;

      this.matSnackBar.open(`You're logged in as ${loginResponse.User.FullName}`, null, { duration: 3000 });
     } catch (error) {
       this.logger.error('error logging in', error);

       this.matSnackBar.open('Error logging in', null, { duration: 3000 });
     }
  }

  async loadApplications() {
    try {
      const applications = await this.$applicationService.fetch().pipe(take(1)).toPromise();

      // We will always just select the first application
      //
      if ( !isEmpty(applications) ) {
        this.$applicationService.setSelected(applications[0]);
      }
      this.storeInitService.initializeStore(() => {
        this.$openIdAuth.initialise(window.location.href);

        this.$openIdAuth.openIdLoginSuccess$.subscribe((loginData) => {
          if (loginData) {
            this.handleOpenIdLogin(loginData);
          }
        });
      });

    } catch (error) {
      this.logger.error('Error fetching applications', error);

      this.snackbar.open('Failed to establish connection, please choose a different endpoint', null, { duration: 5000 });

      this.router.navigate(['landing']);
    }
  }
}
