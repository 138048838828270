import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { EndPointUrlService } from './end-point-url.service';

export type IServiceResponse = EVA.Core.GetAvailableServiceDetailsResponse;

@Injectable()
export class ServiceSelectorService {

  responseMap = new Map<string, Observable<IServiceResponse>>();

  constructor(private http: HttpClient, private $endPointUrlService: EndPointUrlService) { }

  fetch( serviceType: string ): Observable<IServiceResponse> {

    if ( this.responseMap.get(serviceType) ) {
      return this.responseMap.get(serviceType);
    }

    const stream$ = this.http.post<IServiceResponse>(this.$endPointUrlService.endPointUrl + '/message/GetAvailableServiceDetails', {
      Type: serviceType
    }).pipe( shareReplay() );

    this.responseMap.set(serviceType, stream$);

    return stream$;
  }
}
