import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger, ILoggable } from '../decorators/logger';
import { EndPointUrlService } from './end-point-url.service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

/**
 * This service will be responsible for fetching the eva-typings, it will also store them in indexdb
 * for an optimal testing experience, beside that. Everytime the editor is loaded, it will fetch the typings in the background
 * and notify the user about new typings incase the newly fetched ones are different than the locally stored ones
 */
@Logger
@Injectable()
export class EvaTypingsService implements ILoggable {
  public logger: Partial<Console>;

  private typingsServiceNameResponseMap$ = new Map<string, Observable<string>>();

  constructor(private http: HttpClient, private $endPointUrlService: EndPointUrlService) { }

  getTypingsForService(serviceName: string) {

    if (this.typingsServiceNameResponseMap$.get(serviceName)) {
      this.logger.log(`getTypingsForService existing typings for ${serviceName} response object found, returning it.`);

      return this.typingsServiceNameResponseMap$.get(serviceName);
    }

    const requestUrl = `${this.$endPointUrlService.endPointUrl}/api/definition/typescript_v2/${serviceName}`;

    const typingsServiceNameResponse$ = this.http.get<string>(requestUrl, {
      responseType: 'text' as any
    }).pipe(
      shareReplay()
    );

    typingsServiceNameResponse$.subscribe({
      next: () => {
        // If this service request succeeds, we will save this observable in our local map. Otherwise we won't.
        //
        this.typingsServiceNameResponseMap$.set(serviceName, typingsServiceNameResponse$);
      }
    });


    return typingsServiceNameResponse$;
  }
}
