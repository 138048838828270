import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UiEditorComponent } from './ui-editor/ui-editor.component';
import { IsArrayFieldPipe } from './is-array-field.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { UiEditorArrayComponent } from './ui-editor-array/ui-editor-array.component';
import { IsDictionaryFieldPipe } from './is-dictionary-field.pipe';
import { UiEditorDictionaryComponent } from './ui-editor-dictionary/ui-editor-dictionary.component';
import { UiEditorNumberComponent } from './ui-editor-number/ui-editor-number.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DirectivesModule } from '../directives/directives.module';
import { UiEditorElementComponent } from './ui-editor-element/ui-editor-element.component';
import { IsObjectFieldPipe } from './is-object-field.pipe';
import { UiEditorObjectComponent } from './ui-editor-object/ui-editor-object.component';
import { UiEditorEnumComponent } from './ui-editor-enum/ui-editor-enum.component';
import { IsEnumFieldPipe } from './is-enum-field.pipe';
import { TypingsParserService } from './typings-parser.service';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DirectivesModule,
    MatTooltipModule
  ],
  declarations: [
    UiEditorComponent,
    IsArrayFieldPipe,
    UiEditorArrayComponent,
    UiEditorDictionaryComponent,
    IsDictionaryFieldPipe,
    IsObjectFieldPipe,
    IsEnumFieldPipe,
    UiEditorNumberComponent,
    UiEditorElementComponent,
    UiEditorObjectComponent,
    UiEditorEnumComponent
  ],
  exports: [UiEditorComponent],
  providers: [TypingsParserService]
})
export class UiEditorModule { }
