<aside #sidebar>
  <div class="wrapper">
    <mat-card class="warning" @slideUpDown *ngIf="notLoggedIn$ | async">
      <mat-card-title class="d-flex align-items-center justify-content-between">
        <span>Warning</span>
        <mat-icon>warning</mat-icon>
      </mat-card-title>
      <mat-card-content>
        You are not logged in, and will see a limited subset of the available services. Please login
      </mat-card-content>
    </mat-card>
    <form [formGroup]="searchForm" #form>
      <mat-form-field>
        <input #searchInput matInput color="secondary" placeholder="Search service" formControlName="search"
          autocomplete="off">
      </mat-form-field>

    </form>
    <div class="list" *ngIf=" services$ | async; let services">

      <ng-container *ngIf="(services | fusejs:searchTerms:searchOptions); let fusejsServices">
        <ng-container *ngFor="let service of fusejsServices">
          <button mat-button class="w-100" (click)="selectService(service, $event)" [attr.title]="service.Name">
            <span [innerHTML]="service.fuseJsHighlighted.Name"></span>
            <div class="name-space" [innerHTML]="service.fuseJsHighlighted.Namespace"></div>
          </button>
        </ng-container>
        <div *ngIf="fusejsServices?.length === 0 && services.length > 0" class="pl-2">
          No results found for '{{ searchForm.get('search').value }}'
        </div>
      </ng-container>
    </div>
    <eva-drag direction="x" [showIcon]="false" [elem]="sidebar"></eva-drag>
  </div>
</aside>

<main>
  <ng-container *ngIf="selectedService$.value; else emptyState">
    <eva-tester [selectedService$]="selectedService$"></eva-tester>
  </ng-container>
  <ng-template #emptyState>
    <eva-empty-state title="Select a service to get started" icon="list"></eva-empty-state>
  </ng-template>
</main>

<eva-tester-status-bar></eva-tester-status-bar>
