import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { DirectivesModule } from '../directives/directives.module';
import { EndpointSelectorModalComponent } from './endpoint-selector-modal/endpoint-selector-modal.component';
import { EndpointSelectorComponent } from './endpoint-selector/endpoint-selector.component';

@NgModule({
  declarations: [
    EndpointSelectorModalComponent,
    EndpointSelectorComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatProgressSpinnerModule,
    DirectivesModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTabsModule,
  ],
  entryComponents: [EndpointSelectorModalComponent],
  exports: [EndpointSelectorModalComponent, EndpointSelectorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EndPointSelectionModule { }
