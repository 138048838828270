import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfirmAlertService {

  constructor(private dialog: MatDialog) { }

  confirmDialog(dialogData: ConfirmDialogModel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    return dialogRef.afterClosed().pipe(
      map(dialogResult => dialogResult as boolean)
    );
  }

}
