import { Injectable } from '@angular/core';
import { getCurrentUser, settings, store, listApplicationCultures } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash';
import { noop } from 'redux-saga/utils';
import { defer } from 'rxjs';
import { filter, retry, retryWhen, tap } from 'rxjs/operators';
import { ILoggable, Logger } from '../decorators/logger';
import { bootstrapStore } from '../shared/bootstrap-store';
import { ApplicationsService } from './applications.service';
import { EndPointUrlService } from './end-point-url.service';
import { ListServicesService } from './list-services.service';

@Logger
@Injectable()
export class StoreInitService implements ILoggable {

  logger: Partial<Console>;

   constructor(
    private $endPointUrlService: EndPointUrlService,
    private $listServices: ListServicesService
   ) { }

    /** You only want to init the store if there is an application selected */
  async initializeStore(onBootstrap: Function) {

    // We will subscribe to setting changes in case a user logs in or the session id is set
    //
    (settings.changes$)
      .pipe(
        filter(setting => setting.name === 'userToken'),
        tap(setting => this.logger.log(`${setting.name} has changed from ${setting.old} to ${setting.new}`))
      )
      .subscribe(setting => {
        /** The new value we will be storing in local storage cannot be null or undefined. So we will default that to an empty string */
        const newValue = isNil(setting.new) ? '' : setting.new;

        localStorage.setItem(setting.name, newValue);
      });

    try {
      defer(() => bootstrapStore({
        endPointURL: this.$endPointUrlService.endPointUrl
      })).pipe(
        retryWhen(source => {
          return source.pipe(
            filter(data => data instanceof Response),
            filter((response: Response) => response.status === 403),
            tap(() => settings.userToken = null),
            tap(data => this.logger.warn('[403] Session expired, restarting bootstrap with userToken')),
            retry()
          );
        })
      )
      .subscribe({
        error: error => this.logger.error('error bootstraping store', error),
        next: () => {
          onBootstrap();

          const actions = [
            getCurrentUser.createFetchAction(),
            listApplicationCultures.createFetchAction()
          ];

          actions.forEach( action => store.dispatch(action[0]) );

          const promises: Promise<any>[] = actions.map( action => {
            const promise = action[1];

            return promise;
          } );


          Promise.all(promises)
            .then(noop)
            .catch(err => console.error(err))
            .then(() => this.$listServices.fetch() );
        }
      });
    } catch (e) {
      this.logger.error('failed to fetch environment file', e);
    }
  }

}
