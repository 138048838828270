<form autocomplete="off" [formGroup]="form" class="d-flex">

  <div *ngIf="!field.Fields || field.Fields.length === 0">
    This request has no body.
  </div>

  <div class="forms-container">
    <ng-container *ngFor="let field of field.Fields;">
      <eva-ui-editor-element [field]="field" [isArray]="false" [formControlName]="field.Name" *ngIf="form.get(field.Name)"></eva-ui-editor-element>
    </ng-container>
  </div>
</form>
