<ng-container *ngIf="show">
  <div class="overlay" @fadeInOut (click)="show = false"></div>

  <div class="search-container" @fadeInOut>
    <form [formGroup]="form">
      <input #input class="search" [attr.placeholder]="searchPlaceHolder" type="text" formControlName="mainSearch" >

      <mat-spinner *ngIf="evaSearchResultLoading$ | async" [diameter]="20"></mat-spinner>
      <div class="results-container" #results>
        <ng-container *ngIf="showMainSearchResults">
          <ng-container *ngFor="let action of (actions | fusejs:(mainSearchTerms | async):searchOptions); let index = index">
            <a
              href=""
              (click)="selectMainSearchOpt($event, action.value)"
              (keyup.Enter)="selectMainSearchOpt($event, action.value)"
            >
              <span [innerHTML]="action.fuseJsHighlighted.title"></span>
            </a>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="showEvaResults">
          <div *ngFor="let result of evaSearchResults$ | async; let index = index" @slideUpDown>
            <a
              href=""
              (click)="onResultItemClick($event, result)"
              (keyup.Enter)="onResultItemClick($event, result)"
            >
              <ng-container *ngIf="result.fuseJsHighlighted">
                  <span [innerHTML]="result.fuseJsHighlighted.title"></span>
              </ng-container>
              <ng-container *ngIf="!result.fuseJsHighlighted">
                  <!-- TO:DO let all items go through fusejs pipe, to ensure code consistency and highlight results in relation to the query  -->
                  {{ result.title }}
              </ng-container>
            </a>
          </div>

          <div *ngIf="form.get('mainSearch').value?.length > 0 && (evaSearchResults$ | async)?.length === 0" class="empty">
            {{ (evaSearchResultLoading$ | async) ? 'Loading...' : 'No items'}}
          </div>
        </ng-container>
      </div>
    </form>
  </div>
</ng-container>
