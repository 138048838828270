import { Injectable } from '@angular/core';
import { getCurrentUser } from '@springtree/eva-sdk-redux';
import Dexie from 'dexie';
import { isNil } from 'lodash-es';
import { firstValueFrom } from 'rxjs';
import { ILoggable } from '../decorators/logger';
import isNotNil from '../shared/operators/is-not-nil';
import { EndPointUrlService } from './end-point-url.service';

export interface IDexieEntry {
  id: string;
  prefix: string;
  timestamp: number;
}

export interface IRequestHistoryEntry extends IDexieEntry {
  customer: IRequestHistoryEntryCustomer;
  user: IRequestHistoryEntryUser;
  request: any;
}

export interface IRequestHistoryEntryCustomer  {
  id: number;
  name: string;
}

export interface IRequestHistoryEntryUser  {
  id: number;
  name: string;
}

export type DexieTable = 'requestHistory'; // this can be expanded to include all needed tables.

export async function getPrimaryKeyPrefix() {
  const user = await firstValueFrom(getCurrentUser.getResponse$().pipe(isNotNil()));
  return `${IndexedDbService.endpoint$?.endPointUrl}_${user?.User?.ID}_`;
}

class AppDB extends Dexie implements ILoggable {
  logger: Partial<Console>;

  requestHistory: Dexie.Table<IRequestHistoryEntry, string>;

  constructor(databaseName = 'eva_service_tester') {
    super(databaseName);
    this.version(1).stores({
      requestHistory: 'id, timestamp, customer',
    });
    this.requestHistory = this.table('requestHistory');

    this.on('ready', () => {
      this.setupHooks();
    });
  }

  setupHooks() {
    this.requestHistory.hook('creating', (primaryKey, obj) => {
      obj.id = `${obj.prefix}${obj.id}_${obj.timestamp}`;

      return obj.id;
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {
  private appDb = new AppDB();
  public static endpoint$: EndPointUrlService;

  constructor(endpoint$: EndPointUrlService) {
    IndexedDbService.endpoint$ = endpoint$;
    this.appDb.open();
  }

  addEntity(table: DexieTable, entity: any) {
    return this.appDb.table(table).add(entity);
  }

  getAllEntities<T = any>(table: DexieTable, prefix: string, orderBy: { field: string, ascending: boolean } = { field: 'timestamp', ascending: false }) {
    const records = this.appDb.table(table).where('id').startsWith(prefix);

    if (!isNil(orderBy.field) && orderBy.field !== '') {
      if (!orderBy.ascending) {
        return records.reverse().sortBy(orderBy.field) as Promise<T[]>;
      }

      return records.sortBy(orderBy.field) as Promise<T[]>;
    }

    return records.toArray() as Promise<T[]>;
  }

  removeEntity(table: DexieTable, id: string) {
    return this.appDb.table(table).delete(id);
  }
}
