import { core, settings } from '@springtree/eva-sdk-redux';
import nodePackage from 'package.json';

const nodePackageVersion = nodePackage.version;
const nodePackageName = nodePackage.name;

/**
 * Describes a configuration file
 */
export interface IEnvironment {
  defaultToken?: string;
  endPointURL: string;
}


/**
 * This will bootstrap the store by initalizing it with the loaded environment file
 * It will also do other essential things like loading the current application configuration and fetch the current user
 * */
export const bootstrapStore = (env: IEnvironment): Promise<any[]> => {

  // Enable potential discount options (GWP)
  //
  settings.productSearchOptions.IncludePrefigureDiscounts = false;
  settings.shoppingCartOptions.IncludePrefigureDiscounts = false;
  settings.currentOrderOptions.IncludePrefigureDiscounts = false;

  // Provide our application details for the EVA user agent
  //

  // Setting the user token
  //
  const userToken: string = localStorage.getItem('userToken');

  // Initialize the SDK
  //
  const bootstrapPromise = core.bootstrap({
    debug: false,
    endPointUrl: env.endPointURL,
    defaultToken: env.defaultToken,
    appName: nodePackageName,
    appVersion: nodePackageVersion,
    enableSentinel: false,
    disableCartBootstrap: true,
    disableDataBootstrap: true,
    startCommunicator: false,
    defaultCallUserType: 'employee',
    userToken
  });

  return bootstrapPromise;
};
