<h6 class="d-flex align-items-center">
  <span>{{ field.Name }}</span>
  <mat-icon class="warning ml-2" [matTooltip]="field.Deprecation" *ngIf="field.Deprecation">warning</mat-icon>
  <mat-icon class="ml-2" [matTooltip]="field.Description" *ngIf="field.Description">info</mat-icon>
</h6>


<div class="main-container">
  <div class="line"></div>
  <div class="forms-container">
    <form [formGroup]="form">
      <ng-container *ngFor="let field of fields; let index = index">
        <div class="form-container" formArrayName="elements">
          <div class="plus-dots"></div>
          <!--  [formControl]="form.get('elements.' + index + '.key')" -->
          <ng-container *ngIf="form.get('elements.' + index); let formGroup">
            <mat-form-field appearance="outline">
              <mat-label>Field name</mat-label>
              <input matInput type="string" [formControl]="formGroup.get('key')">
            </mat-form-field>

            <eva-ui-editor-element [field]="field" [formControl]="formGroup.get('value')"></eva-ui-editor-element>
          </ng-container>
        </div>
      </ng-container>
    </form>
    <div class="d-flex align-items-center">
      <div class="plus-dots"></div>
      <button mat-button (click)="addElement()">
        <mat-icon>add_circle_outline</mat-icon> ADD A FIELD
      </button>
    </div>
  </div>

</div>

