import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger, ILoggable } from '../decorators/logger';
import { first } from '../../../node_modules/rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { EndPointUrlService } from './end-point-url.service';


export type IListServiceItem = EVA.Core.GetAvailableServicesResponse.Service;

@Logger
@Injectable()
export class ListServicesService implements ILoggable {
  public logger: Partial<Console>;

  public services$ = new BehaviorSubject<IListServiceItem[]>([]);

  constructor(private http: HttpClient, private $endPointUrlService: EndPointUrlService) {}

  fetch() {
    this.services$.next([]);

    this.http.post<EVA.Core.GetAvailableServicesResponse>
    (this.$endPointUrlService.endPointUrl + '/message/GetAvailableServices', {})
      .pipe( first() )
      .subscribe( serviceResponse => this.services$.next(serviceResponse.Services) );
  }

}
