import { Component, ElementRef, Input, ViewChild, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash-es';
import { fadeInOut, slideUpDown } from 'src/app/shared/animations';
import { ClipboardService } from 'src/app/services/clipboard.service';

@Component({
  selector: 'eva-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '(drop)': 'drop($event)',
    '(dragleave)': 'dragLeave()',
    '(dragover)': 'dragOver($event)',
    'tabindex': '0'
  },
  animations: [slideUpDown, fadeInOut]
})
export class FilePickerComponent {

  @ViewChild('fileInput') fileInput: ElementRef;

  /** The current selected file */
  public currentFile: File;

  /** Highlight the border so the user realizes they are DnDing */
  @HostBinding('class.highlight')
  public highlight: boolean;

  /** Whether there is a file selected or not */
  public fileSelected: boolean;

  /** Whether the file is loading or not */
  public loading: boolean;

  @Output() base64Upload = new EventEmitter<string>();

  constructor() {
    this.highlight    = false;
    this.fileSelected = false;
  }

  dragOver(event: Event) {
    this.highlight = true;
    event.stopPropagation();
    event.preventDefault();
    event['dataTransfer'].dropEffect = 'copy';
  }

  dragLeave() {
    this.highlight = false;
  }

  /**
   * Triggered whenever a file is DnDed
   * @param event
   */
  drop(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    // Turning off the highlight
    //
    this.highlight = false;
    // Adds a file to the selection (if there is any)
    //
    this.selectFile(event['dataTransfer'].files);
  }

  /**
   * Whenever this component is clicked, we want to show the filesystem
   */
  @HostListener('click')
  onClick() {
    const el: HTMLElement = this.fileInput.nativeElement;

    el.click();
  }

  /**
   * Cancels the selection and resets this element to its intial state
   */
  cancelSelection(event: MouseEvent): void {
    // Ensures the upload image doesn't get clicked
    //
    event.stopPropagation();

    this.currentFile = null;

    this.fileSelected = false;
  }

  /**
   * Checks if there is a file available in the files array and emits it oout
   * @param files
   */
  public selectFile(files: File[]): void {
    // Resetting this boolean
    //
    this.fileSelected = false;

    if (!_.isEmpty(files)) {
      const fileReader = new FileReader();

      const file = files[0];

      fileReader.readAsDataURL(file);

      this.loading = true;

      fileReader.onload = () => {
        this.loading = false;

        this.currentFile = file;

        this.fileSelected = true;

        console.log(fileReader.result as string);

        this.base64Upload.emit(fileReader.result as string);
      };
    }
  }
}
