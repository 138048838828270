import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';


export type TPossibleTheme = 'dark' | 'light' | 'dora';

/** This will switch the theme of the application by adding a class on the body */
@Injectable()
export class ThemeService {

  public themeList = [{
    title: 'Light',
    id: 'light'
  }, {
    title: 'Dark',
    id: 'dark'
  },
  {
    title: 'Dutchdelux (BETA)',
    id: 'dutchdelux'
  },
  {
    title: 'Winter Is here',
    id: 'winterIsHere'
  },
  {
    title: 'CoralHeaven',
    id: 'coralHeaven'
  },
  {
    title: 'Hacker vibes',
    id: 'hackerVibe'
  },
  {
    title: 'unicorn mode',
    id: 'unicornMode'
  }];

  private _theme: TPossibleTheme = 'light';

  public get theme(): TPossibleTheme {
    return JSON.parse(localStorage.getItem('theme')) || this._theme;
  }

  public set theme(newTheme: TPossibleTheme) {
    if (this._theme !== newTheme) {
      this._theme = newTheme;
      localStorage.setItem('theme', JSON.stringify(newTheme));

      this.switchTheme(newTheme);
    }
  }

  constructor(@Inject(DOCUMENT) private document) {
    this.switchTheme(this.theme);
  }

  switchTheme(newTheme: TPossibleTheme) {
    const html = (this.document as Document).documentElement;
    // Removing old theme
    //
    html.classList.remove(this.theme);

    // Adding new theme
    //
    html.classList.add(newTheme);

    this.theme = newTheme;
  }

}
