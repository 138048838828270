<footer class="status-bar">
  <button mat-button (click)="switchEndpoint()" [matTooltip]="namespaceTooltip$ | async">
    <mat-icon>link</mat-icon>
    <code>
      {{currentEndpoint}}
    </code>
    <eva-config-picker-endpoint-status class="ml-2" [endpoint]="currentEndpoint"></eva-config-picker-endpoint-status>
  </button>
  <button mat-button [matTooltip]="versionTooltip$ | async" (click)="openEndpointInfo()" *ngIf="endpointInfoVersion$ | async as endpointInfoVersion" @fadeInOut>
    <code>
      {{ endpointInfoVersion }}
    </code>
  </button>
  <button mat-button *ngIf="environmentModeButton$ | async as environmentModeButton" @fadeInOut [ngClass]="environmentModeButton.cssClass">
    <mat-icon>
      {{environmentModeButton.icon}}
    </mat-icon>
      {{ environmentModeButton.text }}
  </button>

  <div class="left-container">
    <button mat-button matTooltip="You're currently logged in this EVA user." *ngIf="currentUserName$ | async as currentUserName" @fadeInOut>
      <!-- <mat-icon>account_circle</mat-icon> -->
      <div class="image-container">
        <img [src]="userImage$ | async" alt="user image" eva-image>
      </div>
      {{ currentUserName }}
    </button>
    <button mat-button matTooltip="You're currently logged in this organisation." (click)="changeOrganization()" *ngIf="currentOrganizationName$ | async as currentOrganizationName" @fadeInOut>
      <mat-icon>business</mat-icon> {{ currentOrganizationName }}
    </button>
    <button mat-button (click)="openCommandPallete()" matTooltip="You can open with ⌘ + P or CTRL + P">
      <mat-icon>launch</mat-icon>Commands
    </button>
    <button mat-button (click)="openSlack()" class="feedback" matTooltip="Have slack? You can send feedback using this button.">
      <mat-icon>feedback</mat-icon>
    </button>
  </div>
</footer>
