import { Component, OnInit } from '@angular/core';
import { EndPointUrlService } from '../../../services/end-point-url.service';
import { startWith, map } from 'rxjs/operators';
import { BaseEnvironment } from '@springtree/eva-config-picker';
import { Observable } from 'rxjs';

@Component({
  selector: 'eva-endpoint-selector-modal',
  templateUrl: './endpoint-selector-modal.component.html',
  styleUrls: ['./endpoint-selector-modal.component.scss']
})
export class EndpointSelectorModalComponent {

  constructor(private $endPointUrl: EndPointUrlService) { }

  endpointSelected(event: CustomEvent<BaseEnvironment>) {
    this.$endPointUrl.onChange(event.detail.endpoint);
  }

}
