import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { getCurrentUser } from '@springtree/eva-sdk-redux';
import { isNil, cloneDeep } from 'lodash-es';
import { Subject, of, combineLatest ,  BehaviorSubject ,  Observable } from 'rxjs';
import { map, pairwise, take, takeUntil, distinctUntilChanged, tap, catchError } from 'rxjs/operators';
import { ILoggable, Logger } from 'src/app/decorators/logger';
import { LoginModalComponent } from 'src/app/modules/login/login-modal/login-modal.component';
import { ContextMenuController } from '../..//modules/context-menu';
import isRequired from '../../decorators/is-required';
import { IServiceChangePayload } from '../../pages/tester-container/tester-container.component';
import { IListServiceItem } from '../../services/list-services.service';
import { IServiceResponse, ServiceSelectorService } from '../../services/service-selector.service';
import { ESelectedTabIndex, TEditorContainerState } from '../service-tester/service-tester.component';
import { ConfirmAlertService } from 'src/app/shared/confirm-alert/confirm-alert.service';

/** Represents an editor tab */
export interface ITesterState {
  listMetaData: IListServiceItem;
  detailMetaData: Observable<IServiceResponse>;
  tabName: string;
  editorModel: string;
  response: string;
  activeTab: ESelectedTabIndex;
}

@Logger
@Component({
  selector: 'eva-tester',
  templateUrl: './tester.component.html',
  styleUrls: ['./tester.component.scss']
})
export class TesterComponent implements OnInit, OnDestroy, ILoggable {
  logger: Partial<Console>;

  public defaultSelectedServices = [];
  // public defaultSelectedServices = [{
  //   tabName: 'Service',
  //   editorModel: null,
  //   response: null,
  //   detailMetaData: null,
  //   listMetaData: null
  // }];

  public selectedServices: Partial<ITesterState>[] = cloneDeep(this.defaultSelectedServices);

  @Input()
  @isRequired
  selectedService$: BehaviorSubject<IServiceChangePayload>;

  /** The index of the selected tab */
  public selectedTabIndex = 0;

  /** if there is no user logged in, we want to show a UI to fix this */
  isLoggedIn$ = getCurrentUser.getState$().pipe(
    map(userState => userState.isLoggedIn )
  );

  /** despite your session timing out, you can decide you want to continue using doras an anonymous user */
  forceHideLoginAgainUserInterface$ = new BehaviorSubject(false);

  showLoginAgainUserInterface$ = combineLatest([
    this.isLoggedIn$.pipe(
      distinctUntilChanged(),
      pairwise(),
      tap(([prevLoggedInState, nextLoggedInState]) => {
        this.logger.log(`showLoginButton = prevLoggedInState=${prevLoggedInState} nextLoggedInState=${nextLoggedInState}`);
      }),
      map(([prevLoggedInState, nextLoggedInState]) => prevLoggedInState === true && nextLoggedInState === false)
    ),
    this.forceHideLoginAgainUserInterface$,
  ]).pipe(
    map(([showLoginAgainUserInterface, forceHideLoginAgainUserInterface]) => {
      return showLoginAgainUserInterface && !forceHideLoginAgainUserInterface;
    })
  );

  showLoginAgainUserInface$ = getCurrentUser.expired$;

  stop$ = new Subject<void>();

  constructor(
    private $serviceSelector: ServiceSelectorService,
    private contextMenuCtrl: ContextMenuController,
    private matDialog: MatDialog,
    private confirmAlertService: ConfirmAlertService
  ) {}

  @HostListener('document:contextmenu', ['$event']) onclick(event: MouseEvent) {
    console.log('click..', event);

    const targetEl: HTMLElement = ((event as any).path as HTMLElement[])
      .filter(el => !isNil(el.classList))
      .find( el => el.classList.contains('mat-tab-label') );

    /**
     * We are only interested in mat tab labels that fall under our tabs
     * ⚠️ TO:DO refactor this to use viewChild of mat-tab-group in the template
     */
    const ourTabs = document.querySelector('.services-tabs').contains(targetEl);

    if ( targetEl && ourTabs ) {

      event.preventDefault();

      const parent = targetEl.parentElement;

      const nodes: HTMLElement[] = Array.prototype.slice.call(parent.children);

      if ( nodes.length  === 1 ) {
        return;
      }

      const index = nodes.indexOf(targetEl);

      this.contextMenuCtrl.present({
        event,
        menu: [{
          title: 'Delete',
          handler: () => this.removeTab(index),
        }]
      });
    }
  }

  ngOnInit(): void {
    this.selectedService$.pipe(
      takeUntil(this.stop$)
    ).subscribe( serviceChangePayload => {
      if ( serviceChangePayload.newTab ) {
        this.addTab();
      }

      this.selectedServices[this.selectedTabIndex] = {
        tabName: serviceChangePayload.service.Name,
        detailMetaData: this.performDetailMetaDataCall(serviceChangePayload.service),
        listMetaData: serviceChangePayload.service,
        editorModel: null,
        response: null
      };
    });
  }

  performDetailMetaDataCall({Type}: EVA.Core.GetAvailableServicesResponse.Service) {
    return this.$serviceSelector.fetch(Type).pipe(
      // We need to return this to ensure this observables identity is different based on whether it errored out or not
      // this is important to ensure change detection is triggered in the child component (service-tester.component)
      //
      catchError(() => of(null))
    );
  }

  public addTab() {
    this.selectedServices.push({
      tabName: null,
      response: null,
      detailMetaData: null,
      editorModel: null,
      listMetaData: null,
      activeTab: ESelectedTabIndex.REQUEST
    });

    this.selectedTabIndex = this.selectedServices.length - 1;
  }

  selectedServiceChange(serviceName: IListServiceItem, index: number) {
    this.selectedServices[index].tabName = serviceName.Name;
  }

  updateServicesState(editorContainerState: TEditorContainerState, selectedTabIndex: number) {
    this.selectedServices[selectedTabIndex].editorModel = editorContainerState.editorModel;
    this.selectedServices[selectedTabIndex].response = editorContainerState.response;
    this.selectedServices[selectedTabIndex].activeTab = editorContainerState.activeTab;
  }

  public removeTab(index: number) {
    this.selectedServices.splice(index, 1);
  }

  openLoginModal() {
    const dialog = this.matDialog.open(LoginModalComponent, {
      disableClose: true
    });

    dialog.afterOpened().subscribe(() => {
      getCurrentUser.getState$()
        .pipe(
          map(state => state.isLoggedIn),
          pairwise(),
          take(1)
        ).subscribe(([prev, next]) => {
          // if state goes from is not logged in => is logged in . We will retry the failed requests
          //
          if ( prev === false && next === true) {
            this.updateSelectedServices();
          }
        });
    });
  }

  updateSelectedServices() {
    this.selectedServices = this.selectedServices.map(selectedService => {
      /** We will re-create this object and call the performDetailMetaDataCall function again */
      const newTesterState: Partial<ITesterState> = {
        ...selectedService,
        detailMetaData: this.performDetailMetaDataCall(selectedService.listMetaData),
      };

      return newTesterState;
    });
  }

  forceHideLoginAgainUserInterface() {
    this.confirmAlertService.confirmDialog({
      title: 'Are you sure?',
      message: 'This will reset your services selection'
    }).subscribe( confirmed => {
      this.logger.log(`forceHideLoginAgainUserInterface ${confirmed}`);
      if (confirmed) {
        this.forceHideLoginAgainUserInterface$.next(true);

        /** its important we reset this array to ensure the user cannot use any services they dont have access to anymore */
        this.selectedServices = this.defaultSelectedServices;

        this.selectedTabIndex = 0;
      }
    });

  }

  ngOnDestroy() {
    this.stop$.next();
  }
}
