import { OverlayContainer } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getCurrentUser, logout, settings, store } from '@springtree/eva-sdk-redux';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { LoginModalComponent } from 'src/app/modules/login/login-modal/login-modal.component';
import { ListServicesService } from 'src/app/services/list-services.service';
import { ILoggable, Logger } from '../../decorators/logger';
import { slideUpDown } from '../../shared/animations';


@Logger
@Component({
  selector: 'eva-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [slideUpDown]
})
export class HeaderComponent implements ILoggable {
  public logger: Partial<Console>;

  public user$ = getCurrentUser.getLoggedInUser$();

  public noUser$: Observable<boolean> = getCurrentUser.getState$().pipe(
    filter(state => !state.isFetching),
    withLatestFrom(getCurrentUser.getLoggedInUser$()),
    map(([_currentUser, currentLoggedInUser]) => !Boolean(currentLoggedInUser))
  );

  constructor(
    public overlayContainer: OverlayContainer,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private $listServices: ListServicesService
  ) { }

  async logout() {
    const [action, fetchPromise] = logout.createFetchAction();

    store.dispatch(action);

    try {
      await fetchPromise;

      this.snackBar.open('Logged out', null, { duration: 3000 });

      this.$listServices.fetch();
    } catch (e) {
      this.snackBar.open('Failed to logout', null, { duration: 3000 });
    }
  }

  openLoginModal() {
    const dialog = this.matDialog.open(LoginModalComponent);

    dialog.afterOpened().subscribe(() => {
      console.log('finished');
    });
  }

  expire() {
    // TODO remove
    //
    settings.userToken = 'bla';
  }
}
