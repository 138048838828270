<div *ngIf="security?.RequiredFunctionalities?.length">
  <h6>Required functionalities</h6>
  <span class="body-1" *ngFor="let item of security?.RequiredFunctionalities">
    {{item.Functionality}}: {{translateScope(item.Scope)}}
  </span>
</div>
<mat-divider></mat-divider>
<div class="spacer">
  <h6>Required user type</h6>
  <span class="body-1">
    {{translateUserType(security?.RequiredUserType)}}
  </span>
</div>
<mat-divider></mat-divider>
<div class="spacer">
  <h6>Requires authentication</h6>
  <span class="body-1">
    {{security?.RequiresAuthentication ? "Yes" : "No"}}
  </span>
</div>
<mat-divider></mat-divider>
