<div class="form-container align-items-center" [ngSwitch]="field.Type">
  <div class="plus-dots"></div>

  <div class="count" *ngIf="isArray">{{ arrayIndex }}</div>

  <mat-form-field appearance="outline" *ngSwitchCase="'String'">
    <mat-label>{{field.Name}}</mat-label>
    <input matInput [placeholder]="field.Name" [formControl]="mainControl">
    <mat-icon matSuffix>font_download</mat-icon>
    <mat-hint class="d-flex align-items-stretch" *ngIf="field.Deprecation">
      <mat-icon class="warning mr-1">warning</mat-icon>
      <span [matTooltip]="field.Deprecation">This field is deprecated, hover for more info</span>
    </mat-hint>
    <mat-hint class="d-flex align-items-stretch" *ngIf="field.Description && !field.Deprecation">
      <mat-icon class="mr-1">info</mat-icon>
      <span [matTooltip]="field.Description">Hover for field description</span>
    </mat-hint>
  </mat-form-field>

  <eva-ui-editor-number [formControl]="mainControl" [field]="field" *ngSwitchCase="'Int32'"></eva-ui-editor-number>
  <eva-ui-editor-number [formControl]="mainControl" [field]="field" *ngSwitchCase="'Decimal'"></eva-ui-editor-number>

  <mat-form-field *ngSwitchCase="'DateTime'">
    <input matInput [matDatepicker]="picker" [placeholder]="field.Name" [formControl]="mainControl">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-hint class="d-flex align-items-stretch" *ngIf="field.Deprecation">
      <mat-icon class="warning mr-1">warning</mat-icon>
      <span [matTooltip]="field.Deprecation">This field is deprecated, hover for more info</span>
    </mat-hint>
    <mat-hint class="d-flex align-items-stretch" *ngIf="field.Description && !field.Deprecation">
      <mat-icon class="mr-1">info</mat-icon>
      <span [matTooltip]="field.Description">Hover for field description</span>
    </mat-hint>
  </mat-form-field>

  <mat-form-field *ngSwitchCase="'Boolean'">
    <mat-label>{{field.Name}}</mat-label>
    <mat-select [placeholder]="field.Name" [formControl]="mainControl">
      <mat-option [value]="undefined">-</mat-option>
      <mat-option [value]="true">true</mat-option>
      <mat-option [value]="false">false</mat-option>
    </mat-select>
    <mat-hint class="d-flex align-items-stretch" *ngIf="field.Deprecation">
      <mat-icon class="warning mr-1">warning</mat-icon>
      <span [matTooltip]="field.Deprecation">This field is deprecated, hover for more info</span>
    </mat-hint>
    <mat-hint class="d-flex align-items-stretch" *ngIf="field.Description && !field.Deprecation">
      <mat-icon class="mr-1">info</mat-icon>
      <span [matTooltip]="field.Description">Hover for field description</span>
    </mat-hint>
  </mat-form-field>

  <ng-container *ngSwitchDefault>
    <eva-ui-editor-array [formControl]="mainControl" [field]="field" *ngIf="field | isArrayField"></eva-ui-editor-array>
    <eva-ui-editor-dictionary [formControl]="mainControl" [field]="field" *ngIf="field | isDictionaryField"></eva-ui-editor-dictionary>
    <eva-ui-editor-object [formControl]="mainControl" [field]="field" *ngIf="(field | isObjectField) && !(field | isArrayField) && !(field | isDictionaryField)"></eva-ui-editor-object>

    <ng-container *ngIf="!(field | isObjectField) && !(field | isArrayField) && !(field | isDictionaryField)">

      <ng-container *ngIf="(typings$ | async) as typings">
        <eva-ui-editor-enum [formControl]="mainControl" [field]="field" *ngIf="field | isEnumField: typings; else elseBlock">
        </eva-ui-editor-enum>
        <!-- <eva-ui-editor-enum [formControl]="mainControl" [field]="field" *ngIf="!(field | isObjectField) && !(field | isArrayField) && !(field | isDictionaryField) && (field | isEnumField: typings)">
        </eva-ui-editor-enum> -->
        <ng-template #elseBlock>
          <div class="unknown-type" *ngIf="isRootElement">
            <h6>{{field.Name}} =</h6>
            <mat-form-field>
              <mat-label>Type</mat-label>
              <mat-select [formControl]="unknownTypeControl">
                <mat-option [value]="'Int32'">Number</mat-option>
                <mat-option [value]="'String'">String</mat-option>
                <mat-option [value]="'Boolean'">Boolean</mat-option>
                <mat-option [value]="'Dictionary'">Dictionary</mat-option>
                <mat-option [value]="'any[]'">Array</mat-option>
              </mat-select>
            </mat-form-field>

            <eva-ui-editor-element [formControl]="mainControl" *ngIf="unknownTypeMockField" [field]="unknownTypeMockField" [isRootElement]="false"></eva-ui-editor-element>
          </div>
        </ng-template>
      </ng-container>

    </ng-container>
  </ng-container>

  <button mat-button mat-icon-button (click)="onDeleteButtonPress(index)" *ngIf="isArray" class="ml-3">
    <mat-icon>remove_circle_outline</mat-icon>
  </button>
</div>
