<div class="d-flex flex-column flex-lg-row" *ngIf="(testerInfo.detailMetaData | async) as currentService">
  <div class="p-2 flex-1">
    <h1>
      <div class="row no-gutters justify-content-between align-items-center">
        <span>{{ testerInfo.listMetaData.Name }}</span>
        <div class="d-flex align-items-center">
          <button mat-icon-button color="primary" (click)="copyServiceUrlToClipBoard()">
            <mat-icon>share</mat-icon>
          </button>
        </div>
      </div>
    </h1>

    <h6>{{ testerInfo.listMetaData.Type }}</h6>

    <eva-markdown-html *ngIf="currentService?.Description" [markdown]="currentService?.Description">
    </eva-markdown-html>
  </div>
  <div class="p-2">
    <eva-service-security [security]="currentService.Security"></eva-service-security>
  </div>
</div>
