<div class="d-flex justify-content-between align-items-center main-header">
  <div class="d-flex align-items-center">
    <svg width="35px" viewBox="0 0 91 38" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs></defs>
      <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard-2" fill="#848484" fill-rule="nonzero">
          <g id="Group">
            <polygon id="Shape" points="0 38 37 38 33.1008969 29 0 29"></polygon>
            <polygon id="Shape"
              points="74.8652968 0 70.1347032 0 54 36.5612472 54 38 72.5 38 72.5 28.9443207 67.2625571 28.9443207 72.5 17.0957684 81.7922374 38 91 38 91 36.5612472">
            </polygon>
            <polygon id="Shape" points="0 23 31 23 27.0621622 14 0 14"></polygon>
            <polygon id="Shape"
              points="54.7732634 0 45.6304063 20.9042316 36.4036697 0 0 0 0 9.05567929 30.5321101 9.05567929 43.2817824 38 47.8951507 38 64 1.43875278 64 0">
            </polygon>
          </g>
        </g>
      </g>
    </svg>

    <mat-divider [vertical]="true"></mat-divider>

    <h6 class="my-0">Service tester</h6>
  </div>
  <div class="d-flex align-items-center justify-content-end">
    <eva-culture-selector *ngIf="user$ | async" class="d-block"></eva-culture-selector>
    <button mat-button class="mx-2" (click)="openLoginModal()" *ngIf="!(user$ | async)">
      EVA Login
    </button>
    <button mat-button class="mx-2" (click)="logout()" *ngIf="user$ | async" color="warn">EVA Logout</button>
  </div>


</div>
