<div class="dropArea d-flex align-items-center justify-content-center">

  <ng-container *ngIf="!fileSelected && !loading">
    <mat-icon>attach_file</mat-icon>
    <span class="text">Upload file</span>
  </ng-container>

  <ng-container *ngIf="currentFile">
    {{ currentFile.name }}
  </ng-container>

  <ng-container *ngIf="loading">
    <mat-spinner color="accent"></mat-spinner>
  </ng-container>

</div>

<input type="file" #fileInput (change)="selectFile($event.target.files)">


<button class="help" mat-icon-button matTooltip="You can upload files and the request will be updated with their base64 representation">
  <mat-icon>help</mat-icon>
</button>
