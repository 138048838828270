import { Component, forwardRef, Input, Optional, SkipSelf, Injectable } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isEqual, isNil } from 'lodash-es';
import { ILoggable, Logger } from 'src/app/decorators/logger';
import { distinctUntilChanged, map, pairwise, startWith, tap } from 'rxjs/operators';
import { EvaTypingsService } from 'src/app/services/eva-typings.service';
import { Observable } from 'rxjs';

export type GetAvailableServiceDetailsResponseTypeDefinition = EVA.Core.GetAvailableServiceDetailsResponse.TypeDefinition;

export interface IExtendedGetAvailableServiceDetailsResponseFieldTypeDefinition extends EVA.Core.GetAvailableServiceDetailsResponse.TypeDefinition {
  value?: any;
}

export interface IExtendedGetAvailableServiceDetailsResponseTypeDefinition extends EVA.Core.GetAvailableServiceDetailsResponse.TypeDefinition {
  Fields: IExtendedGetAvailableServiceDetailsResponseFieldTypeDefinition[];
}

/** Will hold information about the root ui-editor */
@Injectable()
export class RootUiEditorProvider {
  field: GetAvailableServiceDetailsResponseTypeDefinition;
  typings$: Observable<string>;
}

@Logger
@Component({
  selector: 'eva-ui-editor',
  templateUrl: './ui-editor.component.html',
  styleUrls: ['./ui-editor.component.scss'],
  providers: [RootUiEditorProvider, {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiEditorComponent),
    multi: true
  }]
})
export class UiEditorComponent implements ILoggable, ControlValueAccessor {

  logger: Partial<Console>;

  private _field: IExtendedGetAvailableServiceDetailsResponseTypeDefinition;

  public get field(): IExtendedGetAvailableServiceDetailsResponseTypeDefinition {
    return this._field;
  }

  @Input()
  public set field(value: IExtendedGetAvailableServiceDetailsResponseTypeDefinition) {
    if ( value !== this._field ) {
      this._field = value;

      this.logger.log('root field is...', this._field);

      const fieldNames = this.field.Fields.map((field) => field.Name);

      Object.keys(this.form.controls).forEach( formControlName => {
        if (!fieldNames.includes(formControlName)) {
          this.form.removeControl(formControlName);
        }
      });

      (this.field.Fields || []).forEach(field => {
        if (this.form.contains(field.Name)) {
          this.form.get(field.Name).setValue(field.value ?? null, { emitEvent: false });
        } else {
          this.form.addControl(field.Name, this.fb.control(field.value ?? null));
        }
      });

      if (this.isRootUiEditor) {
        this.rootUiEditorProvider.field = this.field;
        this.rootUiEditorProvider.typings$ = this.$evaTypings.getTypingsForService(this.field.Type);
      } else {
        // If this is not the root one, we will ensure this provider mimicks that of the parents
        //
        this.rootUiEditorProvider.field = this.parentRootUiEditorProvider.field;
        this.rootUiEditorProvider.typings$ = this.parentRootUiEditorProvider.typings$;
      }
    }
  }

  form = this.fb.group({});

  isRootUiEditor = !Boolean(this.parentUiEditorComponent);

  constructor(
    private fb: FormBuilder,
    @Optional() @SkipSelf() private parentUiEditorComponent: UiEditorComponent,
    private rootUiEditorProvider: RootUiEditorProvider,
    @Optional() @SkipSelf() private parentRootUiEditorProvider: RootUiEditorProvider,
    private $evaTypings: EvaTypingsService
  ) { }

  writeValue(obj: any): void {
    if ( isNil(obj) ) {
      this.form.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.pipe(
      startWith({}),
      pairwise(),
      distinctUntilChanged((previousValue, newValue) => {
        const previousNewValue = previousValue[1];
        const newNewValue = newValue[1];

        return isEqual(previousNewValue, newNewValue);
      }),
      map(([previousValue, newValue]) => newValue),
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    // TO:DO implement
  }

}
