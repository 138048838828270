import { Component, ElementRef, HostListener, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { getCurrentUser } from '@springtree/eva-sdk-redux';
import { AngularFusejsOptions } from 'angular-fusejs';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject ,  Observable ,  Subject } from 'rxjs';
import { debounceTime, filter, map, mergeMap, tap, withLatestFrom, takeUntil } from 'rxjs/operators';
import { fadeInOut, slideUpDown } from 'src/app/shared/animations';
import { ContextMenuController } from '../../modules/context-menu';
import { IListServiceItem, ListServicesService } from '../../services/list-services.service';

export interface IServiceChangePayload {
  newTab: boolean;
  service: IListServiceItem;
}

@Component({
  selector: 'eva-tester-container',
  templateUrl: './tester-container.component.html',
  styleUrls: ['./tester-container.component.scss'],
  animations: [ fadeInOut, slideUpDown ]
})
export class TesterContainerComponent implements OnInit, OnDestroy {


  @ViewChild('form') formEl: ElementRef;

  @ViewChild('searchInput') searchInput: ElementRef;

  public readonly services$ = this.$listServices.services$;

  public searchOptions: AngularFusejsOptions = {
    keys: ['Name', 'Namespace'],
    maximumScore: 0.2,
    shouldSort: true
  };

  public searchForm = this.formBuilder.group({
    search: [null]
  });

  searchTerms: string;

  public noUser$: Observable<boolean> = getCurrentUser.getState$().pipe(
    filter(state => !state.isFetching),
    withLatestFrom(getCurrentUser.getLoggedInUser$()),
    map(([_currentUser, currentLoggedInUser]) => !Boolean(currentLoggedInUser))
  );

  public notLoggedIn$ = getCurrentUser.getState$().pipe(
    filter( state => state && state.isFetching === false ),
    map( state => state.isAnonymous )
  );


  public selectedService$: BehaviorSubject<IServiceChangePayload> = new BehaviorSubject(null);

  private stop$ = new Subject<void>();

  constructor(
    private contextMenuCtrl: ContextMenuController,
    private $listServices: ListServicesService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.searchForm.get('search').valueChanges.pipe(debounceTime(500)).subscribe((value: string) => {
      this.searchTerms = value;
    });
  }

  ngOnInit() {
    this.route.params.pipe(
      filter(params => Boolean((params as any).serviceName)),
      mergeMap(params => {
        // Updating the current service
        //
        const serviceName: string = params.serviceName as string;


        const testerState$ = this.$listServices.services$.pipe(
          filter(services => isEmpty(services) === false),
          map(services => {
            const matchingService = services.find(service => service.Name.toLowerCase() === serviceName.toLowerCase());

            return matchingService;
          }),
        );

        return testerState$;
      })
    ).subscribe(matchingService => {
      if ( matchingService ) {
        this.selectService(matchingService);
      }
    });

    // TODO refactor this
    //
    getCurrentUser.expired$.pipe(
      takeUntil(this.stop$)
    ).subscribe(() => {
      // whenever the user expires, we want to re-fetch the services list
      //
      this.$listServices.fetch();
    });
  }


  /** Whenever a service is selected, we will fetch it and create a code template */
  selectService(service: IListServiceItem, mouseEvent?: MouseEvent) {
    const newTab = mouseEvent ? mouseEvent.ctrlKey || mouseEvent.metaKey : false;

    const serviceChangePayload: IServiceChangePayload = {
      newTab: newTab,
      service: {
        Name: service.Name,
        Namespace: service.Namespace,
        Type: service.Type,
        AllowPublic: service.AllowPublic,
        AvailableOffline: service.AvailableOffline,
        RequiresElevation: false,
      }
    };

    // console.log('select', serviceChangePayload);

    this.selectedService$.next(serviceChangePayload);
  }

  openContextMenu(event: MouseEvent) {
    event.stopPropagation();

    this.contextMenuCtrl.present({
      event: event,
      menu: [{
        title: 'Copy',
        handler: () => { }
      }, {
        title: 'Delete',
        handler: () => { }
      }]
    });
  }

  @HostListener('window:keydown.meta.s', ['$event'])
  @HostListener('window:keydown.control.s', ['$event'])
  onSaveDown(event: KeyboardEvent) {
    event.preventDefault();

    (this.formEl.nativeElement as HTMLElement).scrollIntoView({
      block: 'start',
      behavior: 'instant'
    } as any);

    (this.searchInput.nativeElement as HTMLElement).focus();
  }

  ngOnDestroy() {
    this.stop$.next();
  }


}
