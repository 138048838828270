 <mat-form-field appearance="outline" *ngIf="!currentSmartUserInterfaceMap">
  <mat-label>{{field.Name}}</mat-label>
  <input matInput [placeholder]="field.Name" type="number" [formControl]="normalControl">
  <mat-icon matSuffix>looks_one</mat-icon>
  <mat-hint class="d-flex align-items-stretch" *ngIf="field.Deprecation">
    <mat-icon class="warning mr-1">warning</mat-icon>
    <span [matTooltip]="field.Deprecation">This field is deprecated, hover for more info</span>
  </mat-hint>
  <mat-hint class="d-flex align-items-stretch" *ngIf="field.Description && !field.Deprecation">
    <mat-icon class="mr-1">info</mat-icon>
    <span [matTooltip]="field.Description">Hover for field description</span>
  </mat-hint>
</mat-form-field>

<mat-form-field *ngIf="currentSmartUserInterfaceMap" class="smart-field">
  <input matInput [placeholder]="field.Name" [matAutocomplete]="auto" [formControl]="normalControl">
  <div matSuffix>
    <mat-icon>assistant</mat-icon>
    <mat-icon class="warning" [matTooltip]="field.Deprecation" *ngIf="field.Deprecation">warning</mat-icon>
    <mat-icon [matTooltip]="field.Description" *ngIf="field.Description">info</mat-icon>
  </div>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let result of searchResults" [value]="result.id">
      <div class="img-container" *ngIf="result.img">
        <img [src]="result.img" eva-image>
      </div>
      {{ result.label }}
    </mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="!currentSmartUserInterfaceMap.shouldBeLoggedIn || (currentSmartUserInterfaceMap.shouldBeLoggedIn && !(isAnonymousUser$ | async))">
    {{ currentSmartUserInterfaceMap.hint }}
  </mat-hint>
</mat-form-field>
