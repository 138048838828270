import { HttpClient } from '@angular/common/http';
import { Component, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getApplicationConfiguration, getCurrentUser, listCountries, searchOrders, searchProducts, searchUsers, settings, store } from '@springtree/eva-sdk-redux';
import { AngularFusejsOptions, FusejsPipe } from 'angular-fusejs';
import { get, isEqual, isNil } from 'lodash-es';
import { debounceTime, distinctUntilChanged, filter, first, map, take, tap } from 'rxjs/operators';
import { ILoggable, Logger } from 'src/app/decorators/logger';
import isNotNil from 'src/app/shared/operators/is-not-nil';
import { GetAvailableServiceDetailsResponseTypeDefinition, RootUiEditorProvider } from '../ui-editor/ui-editor.component';
import { GetLoginOrganizationUnitsForUserService } from 'src/app/services/get-login-organization-units-for-user.service';
import { firstValueFrom } from 'rxjs';

enum SmartUserInterface {
  NONE,
  ORDERS,
  PRODUCTS,
  USERS,
  // IS A STRING !! wont work here
  LANGUAGE,
  // IS A STRING !! wont work here
  COUNTRY,
  ORGANIZATION,
  SHIPPING_METHOD
}

interface SearchItem {
  id: number|string;
  label: string;
  img?: string;
}

interface SearchStrategyMap {
  [key: string]: (query: string) => Promise<SearchItem[]|void>;
}

interface SmartUserInterfaceMap {
  fieldMatchFn: (fieldName: string) => boolean;

  /** This will be used as fallback if no smart UI was found  */
  serviceMatchFn?: (serviceName: string) => boolean;

  matches: SmartUserInterface;
  /** Function to execute if the UI is currently displaying this piece of UI, this is useful for entities
   * that don't have a search service but a list service */
  onMatch?: Function;

  /** Hint to show to the user */
  hint?: string;

  shouldBeLoggedIn?: boolean;
}

/**
 * Some numeric fields represent and ID, we will be adding smart elements for this
 */
@Logger
@Component({
  selector: 'eva-ui-editor-number',
  templateUrl: './ui-editor-number.component.html',
  styleUrls: ['./ui-editor-number.component.scss'],
  providers: [FusejsPipe, {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiEditorNumberComponent),
    multi: true
  }]
})
export class UiEditorNumberComponent implements OnInit, ILoggable, ControlValueAccessor {
  logger: Partial<Console>;

  @Input() field: GetAvailableServiceDetailsResponseTypeDefinition;

  smartUserInterfaceEnum = SmartUserInterface;

  currentSmartUserInterfaceMap: SmartUserInterfaceMap = null;

  smartUserInterfaceMap: Array<SmartUserInterfaceMap> = [
    {
      fieldMatchFn: fieldName => fieldName.includes('ProductID'),
      serviceMatchFn: serviceName => serviceName.includes('product'),
      matches: SmartUserInterface.PRODUCTS,
      hint: 'You can search on products'
    },
    {
      matches: SmartUserInterface.ORDERS,
      fieldMatchFn: fieldName => fieldName.includes('OrderID'),
      serviceMatchFn: serviceName => serviceName.includes('order'),
      hint: 'You can search on orders',
      shouldBeLoggedIn: true,
    },
    {
      matches: SmartUserInterface.USERS,
      fieldMatchFn: fieldName => fieldName.includes('CustomerID') || fieldName.includes('UserID') || fieldName.includes('EmployeeID'),
      serviceMatchFn: serviceName => serviceName.includes('customer') || serviceName.includes('user'),
      hint: 'You can search on users'
    },
    {
      matches: SmartUserInterface.LANGUAGE,
      fieldMatchFn: fieldName => fieldName.includes('LanguageID'),
    },
    {
      matches: SmartUserInterface.COUNTRY,
      fieldMatchFn: fieldName => fieldName.includes('CountryID'),
      onMatch: () => {
        const [action] = listCountries.createFetchAction({
          FetchAll: true
        });
        store.dispatch(action);
      }
    },
    {
      matches: SmartUserInterface.ORGANIZATION,
      fieldMatchFn: fieldName => fieldName.includes('OrganizationUnitID'),
      hint: 'You can search on organizations'
    },
    {
      matches: SmartUserInterface.SHIPPING_METHOD,
      fieldMatchFn: fieldName => fieldName.includes('ShippingMethodID'),
      onMatch: async () => {
        try {
          // tslint:disable-next-line: max-line-length
          this.listShippingMethodsResponse = await this.http.post<EVA.Core.Management.ListShippingMethodsResponse>(settings.endPointURL + '/message/ListShippingMethods', null)
            .toPromise();
        } catch ( error ) {
          this.logger.error('error listing shipping methods');
        }
      },
      hint: 'You can search on shipping methods'
    }
  ];

  private currentUser$ = getCurrentUser.getResponse$().pipe(
    isNotNil(),
  );

  isAnonymousUser$ = this.currentUser$.pipe(
    map((user) => user?.User?.Type === 4),
  );

  searchResults: SearchItem[];

  getApplicationConfigurationAssetsUrl = getApplicationConfiguration.getResponse$().pipe(
    map(getApplicationConfigurationResponse => getApplicationConfigurationResponse.Configuration['Urls:Assets'])
  );

  listShippingMethodsResponse: EVA.Core.Management.ListShippingMethodsResponse;

  smartUserInterfaceSearchStrategyMap: SearchStrategyMap = {
    [SmartUserInterface.PRODUCTS]: async(query) => {
      const isAnonymousUser = await firstValueFrom(this.isAnonymousUser$);

      if (this.currentSmartUserInterfaceMap.shouldBeLoggedIn && isAnonymousUser) {
        return [];
      }

      const [action, searchProductsPromise] = searchProducts.createFetchAction({
        Query: query,
        IncludedFields: ['display_value', 'primary_image']
      });

      store.dispatch(action);

      try {
        const assetBaseUrl: string = await firstValueFrom(this.getApplicationConfigurationAssetsUrl);

        const searchProductsResponse = await searchProductsPromise;

        return searchProductsResponse.Products.map(product => {
          const guid = get(product, 'primary_image.blob');

          const searchItem: SearchItem = {
            id: product.product_id,
            label: product.display_value,
            img: guid ? `${assetBaseUrl}/image/400/400/${guid}.png` : null
          };
          return searchItem;
        });

      } catch ( error ) {
        this.logger.error('error searching products ', error);
      }
    },
    [SmartUserInterface.ORDERS]: async (query) => {
      const isAnonymousUser = await firstValueFrom(this.isAnonymousUser$);

      if (this.currentSmartUserInterfaceMap.shouldBeLoggedIn && isAnonymousUser) {
        return [];
      }

      const [action, searchOrdersPromise] = searchOrders.createFetchAction({
        Query: query
      });

      store.dispatch(action);

      const LanguageID = await firstValueFrom(this.currentUser$.pipe(map((user) => user.User.LanguageID)));

      return searchOrdersPromise.then(searchOrdersResponse => {
        return searchOrdersResponse.Result.Page.map(order => {
          const formattedTotal = new Intl.NumberFormat(LanguageID, {
            style: 'currency', currency: order.CurrencyID
          }).format(order.TotalAmountInTax);
          const searchItem: SearchItem = {
            id: order.ID,
            label: `${order.ID} ${order.Customer ? ' - ' + order.Customer.FullName : ''} (${formattedTotal})`
          };
          return searchItem;
        });
      }).catch( error => {
        this.logger.error('Error performing the `searchOrders` call', error);
      });
    },
    [SmartUserInterface.USERS]: async (query) => {
      const isAnonymousUser = await firstValueFrom(this.isAnonymousUser$);

      if (this.currentSmartUserInterfaceMap.shouldBeLoggedIn && isAnonymousUser) {
        return [];
      }

      const [action, searchUsersPromise] = searchUsers.createFetchAction({
        SearchQuery: query
      });

      store.dispatch(action);

      return searchUsersPromise.then(searchUsersResponse => {
        return searchUsersResponse.Result.Page.map(user => {
          const searchItem: SearchItem = {
            id: user.ID,
            label: user.FullName,
            img: user.GravatarHash ? `https://www.gravatar.com/avatar/${user.GravatarHash}` : null
          };
          return searchItem;
        });
      }).catch(error => {
        this.logger.error('Error performing the `searchUsers` call', error);
      });;
    },
    [SmartUserInterface.ORGANIZATION]: async (query) => {
      const isAnonymousUser = await firstValueFrom(this.isAnonymousUser$);

      if (this.currentSmartUserInterfaceMap.shouldBeLoggedIn && isAnonymousUser) {
        return [];
      }

      const getLoginOrganizationUnitsForUserService = await firstValueFrom(this.$getLoginOrganizationUnitsForUserService.fetch());

      const searchItems: SearchItem[] = (getLoginOrganizationUnitsForUserService?.Result ?? []).map( result => {
        const searchItem: SearchItem = {
          id: result.ID,
          label: result.Name,
        };

        return searchItem;
      });

      const results = this.fusejsPipe.transform(searchItems, query, this.fuseJsSearchOpts);

      return results;
    },
    [SmartUserInterface.SHIPPING_METHOD]: async (query) => {
      const isAnonymousUser = await firstValueFrom(this.isAnonymousUser$);

      if (this.currentSmartUserInterfaceMap.shouldBeLoggedIn && isAnonymousUser) {
        return [];
      }

      const searchItems: SearchItem[] = this.listShippingMethodsResponse.Result.Page.map( shippingMethodPage => {
        const searchItem: SearchItem = {
          id: shippingMethodPage.ID,
          label: shippingMethodPage.Name
        };

        return searchItem;
      });

      const results = this.fusejsPipe.transform(searchItems, query, this.fuseJsSearchOpts);

      return results;
    }
  };

  normalControl = this.fb.control(undefined);

  fuseJsSearchOpts: AngularFusejsOptions = {
    keys: ['label'],
    shouldSort: true,
  };


  registerOnTouchedCb: Function;

  @HostListener('blur')
  onblur() {
    this.registerOnTouchedCb();
  }

  constructor(
    private fb: FormBuilder,
    private fusejsPipe: FusejsPipe,
    private http: HttpClient,
    private rootUiEditorProvider: RootUiEditorProvider,
    private $getLoginOrganizationUnitsForUserService: GetLoginOrganizationUnitsForUserService
  ) { }

  ngOnInit() {
    const smartUserInterfaceMatch = this.findSmartUserInterfaceMatch();

    if (smartUserInterfaceMatch) {
      this.currentSmartUserInterfaceMap = smartUserInterfaceMatch;

      this.normalControl.valueChanges.pipe(
        filter((value) => !isNil(this.currentSmartUserInterfaceMap) && !isNil(value)),
        distinctUntilChanged(isEqual),
        debounceTime(300),
      ).subscribe(async query => {
        const result = await this.smartUserInterfaceSearchStrategyMap[this.currentSmartUserInterfaceMap.matches](query);

        if ( result ) {
          this.searchResults = result;
        }
      });

      if ( smartUserInterfaceMatch.onMatch ) {
        smartUserInterfaceMatch.onMatch();
      }
    }
  }

  findSmartUserInterfaceMatch(): SmartUserInterfaceMap|null {
    let match: SmartUserInterfaceMap = this.smartUserInterfaceMap.find(smartUserInterfaceMap => {
      return smartUserInterfaceMap.fieldMatchFn(this.field.Name);
    });

    // If we don't find a match, we will try to find one by service name
    //
    if (!match && this.field.Name.includes('ID')) {
      match = this.smartUserInterfaceMap
        .filter(smartUserInterfaceMap => smartUserInterfaceMap.serviceMatchFn )
        .find(smartUserInterfaceMap => {
          return smartUserInterfaceMap.serviceMatchFn(this.rootUiEditorProvider.field.Type.toLowerCase());
        });
    }

    return match;
  }


  writeValue(newValue: any): void {
    this.normalControl.setValue(newValue);
  }

  registerOnChange(fn: any): void {
    this.normalControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.registerOnTouchedCb = fn;
  }

}
