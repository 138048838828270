import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CurrentAction } from '../components/command-palette/command-palette.component';

@Injectable()
export class CommandPaletteService {

  private open$ = new Subject<CurrentAction>();

  constructor() { }

  open(wantedAction?: CurrentAction) {
    this.open$.next(wantedAction);
  }

  public onOpen() {
      return this.open$.asObservable();
  }

}
