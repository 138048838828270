import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { EvaTypingsService } from 'src/app/services/eva-typings.service';
import { Logger, ILoggable } from 'src/app/decorators/logger';

@Logger
@Injectable()
export class TypingsParserService implements ILoggable {
  logger: Partial<Console>;

  getMap = new Map<[string, string], RegExpMatchArray>();

  constructor() { }

  get(fieldType: string, fieldName: string, typings: string) {

    if (this.getMap.get([fieldType, fieldName])) {
      return this.getMap.get([fieldType, fieldName]);
    }

    this.logger.time('getting');

    const regex = new RegExp(`(?<=export const ${fieldType} ${fieldName})[\\s\\S]*?(?=})`);

    const match = typings.match(regex);

    this.getMap.set([fieldType, fieldName], match);

    this.logger.timeEnd('getting');

    return match;
  }
}
