import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getCurrentUser, listApplicationCultures, login, settings, store } from '@springtree/eva-sdk-redux';
import { ILoggable, Logger } from 'src/app/decorators/logger';
import { ListServicesService } from 'src/app/services/list-services.service';
import { UsernameCacheService } from 'src/app/services/username-cache.service';
import { ApplicationsService } from '../../../services/applications.service';
import { OpenIdAuthService } from '../open-id-auth.service';
import { GetLoginOrganizationUnitsForUserService } from 'src/app/services/get-login-organization-units-for-user.service';

interface IFormValue {
  Username: string;
  Password: string;
}

// TODO find web interface for this
//
class Credential {}
class IPasswordCredential extends Credential {
  iconURL?: string;
  name: string;
  password: string;
  id: string;
}

declare var PasswordCredential: any;
@Logger
@Component({
  selector: 'eva-login-card',
  templateUrl: './login-card.component.html',
  styleUrls: ['./login-card.component.scss']
})
export class LoginCardComponent implements OnInit, ILoggable {
  logger: Partial<Console>;

  /** Whether to use the root organization unit on the login, this will simply not show the organizations selector */
  @Input() useRootOrganizationUnit: boolean;

  public form = this.formBuilder.group({
    Username: [null, Validators.required],
    Password: [null, Validators.required],
    // ApplicationID: [ get( this.$applications.getSelected(), 'ID'), Validators.required]
  });

  public loginData: Partial<EVA.Core.Login>;


  public loginFetching$ = login.isFetching$();

  showOpenIdButtons = false;

  openIdButtons$ = this.$openIdAuth.providers$;

  constructor(
    private formBuilder: FormBuilder,
    private $applications: ApplicationsService,
    private snackBar: MatSnackBar,
    private $listServices: ListServicesService,
    private $usernameCache: UsernameCacheService,
    private $openIdAuth: OpenIdAuthService,
    private $getLoginOrganizationUnitsForUserService: GetLoginOrganizationUnitsForUserService
  ) { }

  async ngOnInit() {
    // We will get the cached user name when the application changes
    //
    const cachedUserName = this.$usernameCache.get();

    this.form.get('Username').setValue(cachedUserName);
  }

  async autoFillFields() {
    if (this.credentialApiSupported()) {
      try {
        const cred = (await (navigator as any).credentials.get({
          password: true,
          mediation: 'required'
        } as any) as IPasswordCredential);

        if (cred) {
          this.form.setValue({
            ...this.form.value,
            Username: cred.id,
            Password: cred.password
          }, { emitEvent: false });

          this.submit(this.form.value);
        }
        this.logger.log('credentials received', cred);
      } catch (error) {
        this.logger.error('credentials get error', error);
      }
    }
  }

  private credentialApiSupported(): boolean {
    return window['PasswordCredential'] || window['FederatedCredential'];
  }

  submit(value: IFormValue) {
    // Always login as anonymous user
    //
    settings.userToken = null;

    this.performLogin(value);
  }

  async performLogin(formValue: IFormValue) {

    const [action, fetchPromise] = login.createFetchAction({
      Username: formValue.Username,
      Password: formValue.Password,
      SelectFirstOrganizationUnit: true
    });

    store.dispatch(action);

    fetchPromise.then(response => {

      switch (response.Authentication) {
        case 0:
        case 1:
          this.snackBar.open('Failed to login, try again', null, { duration: 3000 });
          break;
        case 2:
          this.onLoginSuccess(response, formValue);
          break;
        case 3:
          this.snackBar.open('NeedsTwoStepAuthentication not supported', null, { duration: 3000 });
          break;
        case 4:
          this.snackBar.open(
            `Organization unit required because the selected application didnt have an OrganizationUnitID`,
            null, {
              duration: 3000
            }
          );
          break;

        default:
          this.logger.warn('Resposne authentication unsupported value' + response.Authentication);
      }
    }).catch(error => {
      this.logger.error(error);

      this.snackBar.open('Failed to login, try again', null, { duration: 3000 });
    });
  }

  openIdLogin(provider: EVA.Authentication.OpenID.AvailableOpenIDConfiguration) {
    this.$openIdAuth.login(provider.ID);
  }

  toggleOpenIdButtonsVisibility() {
    this.showOpenIdButtons = !this.showOpenIdButtons;
  }

  private async onLoginSuccess(response: EVA.Core.LoginResponse, formValue: IFormValue) {
    this.snackBar.open(`Welcome ${response.User.FullName}`, null, { duration: 3000 });

    settings.userToken = response.User.AuthenticationToken;
    let [getCurrentUserAction] = getCurrentUser.createFetchAction({});
    store.dispatch(getCurrentUserAction);

    if (this.credentialApiSupported() ) {
      try {
        const {Name: applicationName} = this.$applications.getSelected();

        const cred = new PasswordCredential({
          id: formValue.Username,
          password: formValue.Password,
          name: `${response.User.FullName} - ${applicationName}`
        });

        (navigator as any).credentials.store(cred);

        this.logger.log('credentials saved');
      } catch ( error ) {
        this.logger.error('saving credentials failed', error);
      }
    }

    // We are using a setTimout for animations
    //
    setTimeout(() => {
      // Refetching the services
      //
      this.$listServices.fetch();

      const [listApplicationCulturesAction] = listApplicationCultures.createFetchAction();

      store.dispatch(listApplicationCulturesAction);

      this.$getLoginOrganizationUnitsForUserService.fetch();

      this.$usernameCache.set(formValue.Username);
    }, 400);
  }

}
