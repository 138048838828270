import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { EndPointUrlService } from './end-point-url.service';


@Injectable({
  providedIn: 'root'
})
export class GetLoginOrganizationUnitsForUserService {

  constructor(private http: HttpClient, private $endPointUrlService: EndPointUrlService) { }

  fetch(request?: EVA.Core.GetLoginOrganizationUnitsForUser): Observable<EVA.Core.GetLoginOrganizationUnitsForUserResponse> {

    const stream$ = this.http.post<EVA.Core.GetLoginOrganizationUnitsForUserResponse>(this.$endPointUrlService.endPointUrl + '/message/getLoginOrganizationUnitsForUser', request).pipe( shareReplay() );


    return stream$;
  }
}
