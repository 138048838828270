import { Component, Input } from '@angular/core';

@Component({
  selector: 'eva-service-security',
  templateUrl: './service-security.component.html',
  styleUrls: ['./service-security.component.scss']
})
export class ServiceSecurityComponent {

  @Input()
  security: EVA.Core.GetAvailableServiceDetailsResponse.SecurityInfo;

  translateScope(scope: number) {
    switch (scope) {
      case 0:
        return "None";
      case 1:
        return "Create";
      case 2:
        return "Edit";
      case 4:
        return "Delete";
      case 8:
        return "View";
      case 31:
        return "Manage";
      case 32:
        return "Settings";
      case 64:
        return "Scripting";
      case 127:
        return "All";
    }
  }

  translateUserType(type: number) {
    const requiredUserTypes = [];
    if (type === 0) {
      requiredUserTypes.push("None");
    }
    if ((type & 1) === 1) {
      requiredUserTypes.push("Employee");
    }
    if ((type & 2) === 2) {
      requiredUserTypes.push("Customer");
    }
    if ((type & 4) === 4) {
      requiredUserTypes.push("Anonymous");
    }
    if ((type & 8) === 8) {
      requiredUserTypes.push("Business");
    }
    if ((type & 17) === 17) {
      requiredUserTypes.push("System");
    }
    if ((type & 64) === 64) {
      requiredUserTypes.push("Debtor");
    }
    if ((type & 256) === 256) {
      requiredUserTypes.push("Limited Trust");
    }
    if ((type & 512) === 512) {
      requiredUserTypes.push("Tester");
    }
    if ((type & 1024) === 1024) {
      requiredUserTypes.push("Removed By Request");
    }
    if ((type & 2048) == 2048) {
      requiredUserTypes.push("API");
    }
    return requiredUserTypes.join(", ");
  }
}
