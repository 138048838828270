<h6 class="d-flex align-items-center">
  <span>{{ field.Name }}</span>
  <mat-icon class="warning ml-2" [matTooltip]="field.Deprecation" *ngIf="field.Deprecation">warning</mat-icon>
  <mat-icon class="ml-2" [matTooltip]="field.Description" *ngIf="field.Description">info</mat-icon>
</h6>

<div class="main-container">
  <div class="line"></div>

  <div class="forms-container">
    <form [formGroup]="mainControl">
      <div *ngFor="let field of fields; let last = last, let index = index" class="form-container" formArrayName="elements">
        <eva-ui-editor-element
          [isArray]="true"
          [field]="field"
          [arrayIndex]="index"
          [onDeleteButtonPress]="removeElement"
          *ngIf="mainControl.get('elements.' + index)"
          [formControlName]="index"
        >
        </eva-ui-editor-element>
      </div>
    </form>

    <div class="d-flex align-items-center">
      <div class="plus-dots"></div>
      <button mat-button (click)="addElement()">
        <mat-icon>add_circle_outline</mat-icon> ADD A FIELD
      </button>
    </div>
  </div>
</div>
