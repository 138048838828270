<mat-tab-group>
  <mat-tab label="Manual setup">
    <div class="container mt-3">
      <h5>Manual setup</h5>

      <p>If you already have an endpoint, just fill it in and we are ready to go 🚀</p>

      <mat-form-field>
        <input matInput [placeholder]="defaultEndPoint" [formControl]="endPointFormControl">
        <mat-error *ngIf="endPointFormControl.hasError('invalidUrl')">
          URL is invalid
        </mat-error>
        <eva-config-picker-endpoint-status matSuffix [endpoint]="endPointFormControl.value"></eva-config-picker-endpoint-status>
      </mat-form-field>

      <button mat-stroked-button (click)="endPointSelected(endPointFormControl.value)" [disabled]="endPointFormControl.invalid" class="ml-1">
        Set endpoint
      </button>


      <mat-spinner *ngIf="customEndPointValidation.customEndPointValidationLoading" diameter=25></mat-spinner>

      <p
        *ngIf="customEndPointValidation.customEndPointValidationFeedback && !customEndPointValidation.customEndPointValidationLoading">
        {{ customEndPointValidation.customEndPointValidationFeedback }}


        <button mat-stroked-button (click)="setEndpoint(endPointFormControl.value)" class="ml-1">
          Proceed
        </button>
      </p>


    </div>
  </mat-tab>
  <mat-tab label="Endpoints list">
    <div class="mt-3">
      <h5>Endpoints list</h5>

      <p>
        Log in to see a list of all endpoints
      </p>

      <eva-config-picker (endPointSelect)="configPickerEndPointSelected($event)"></eva-config-picker>

    </div>
  </mat-tab>
</mat-tab-group>
