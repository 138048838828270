import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ILoggable, Logger } from '../decorators/logger';

export enum EndPointValidation {
  valid,
  invalid,
  down
}

/**
 * The end point URL can be changed runtime, all services will be using this service to know where to point at
 */
@Logger
@Injectable()
export class EndPointUrlService implements ILoggable {
  public logger: Partial<Console>;

  /** Fallback endpoint url if none are present */
  public DEFAULT_ENDPOINT_URL = 'https://api.eva-dev.on-eva.io';

  private _selectedEndPointUrl: string;

  public get endPointUrl(): string {
    return this._selectedEndPointUrl || JSON.parse(localStorage.getItem('endPointUrl')) || this.DEFAULT_ENDPOINT_URL ;
  }

  public set endPointUrl( endPointUrl: string ) {
    if ( endPointUrl !== this._selectedEndPointUrl ) {
      this._selectedEndPointUrl = endPointUrl;

      this.endPointUrlManuallySet = true;

      if ( this.endPointUrls.includes(endPointUrl) === false ) {
        this.endPointUrls = [ endPointUrl, ...this.endPointUrls ];
      }

      localStorage.setItem('endPointUrl', JSON.stringify(endPointUrl));
    }
  }


  private _endPointUrls: string[];

  public get endPointUrls(): string[] {
    return this._endPointUrls || JSON.parse(localStorage.getItem('endPointUrls')) || [];
  }

  public set endPointUrls( endPointUrls: string[] ) {
    this._endPointUrls = endPointUrls;

    localStorage.setItem('endPointUrls', JSON.stringify(endPointUrls));
  }

  private _endPointUrlManuallySet = false;

  /** Whether the endpoint was manually set or not, we need this to ensure we can differentiate between the default endpoint and the user selecting it */
  public get endPointUrlManuallySet(): boolean {
    return this._endPointUrlManuallySet || JSON.parse(localStorage.getItem('endPointUrlManuallySet')) || false;
  }

  public set endPointUrlManuallySet(endPointUrlManuallySet: boolean) {
    this._endPointUrlManuallySet = endPointUrlManuallySet;

    localStorage.setItem('endPointUrlManuallySet', JSON.stringify(endPointUrlManuallySet));
  }

  constructor( private snackbar: MatSnackBar, private http: HttpClient, private router: Router ) { }

  async onChange(endPointUrl: string) {
    const keysToReset = ['selectedApplication', 'userToken', 'selectedCulture'];

    keysToReset.forEach( key => localStorage.removeItem(key) );

    try {
      const url = new URL(endPointUrl);

      // If the URL ends with /api we don't want to make the URL's origin the endpoint
      //
      if (url.pathname.includes('/api')) {
        this.endPointUrl = url.href;
      } else {
        // If the URL doesnt end with /api we will make the URL's origin the endpoint
        //
        const origin = url.origin;

        this.endPointUrl = origin;
      }

      const title = document.head.querySelector('title').innerText;

      await this.router.navigate([''], { replaceUrl: true });

      window.history.replaceState({}, title, '');

      location.reload();
    } catch (e) {
      this.logger.error('Error parsing the given URL, please try again');

      this.snackbar.open('Error parsing the given URL, please try again', null, { duration: 5000 });
    }

  }

  /** Sometimes the user will just fill in an endpoint, we will validate it actually exists or is live as feedback */
  async validateEndPoint(endPointUrl: string): Promise<EndPointValidation> {

    const endpointUrlWithoutTrailingSlash = endPointUrl.endsWith('/') ? endPointUrl.slice(0, endPointUrl.length - 1) : endPointUrl;

    const promise = this.http.get(endpointUrlWithoutTrailingSlash + '/status').pipe(first()).toPromise();

    try {
      const json: any = await promise;

      return json.status === 'OK' ? EndPointValidation.valid : EndPointValidation.down;

    } catch (error) {
      return EndPointValidation.invalid;
    }
  }
}
