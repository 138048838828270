import { Pipe, PipeTransform } from '@angular/core';
import { GetAvailableServiceDetailsResponseTypeDefinition } from './ui-editor/ui-editor.component';
import { isEmpty } from 'lodash-es';

/** Determines if a given field is an array or not */
@Pipe({
  name: 'isDictionaryField'
})
export class IsDictionaryFieldPipe implements PipeTransform {

  transform(value: GetAvailableServiceDetailsResponseTypeDefinition): boolean {
    return value.Type.startsWith('Dictionary');
  }

}
