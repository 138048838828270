<div class="d-flex h-100">

  <aside>
    <div class="wrapper">
      <form>
        <mat-form-field>
          <input #searchInput matInput color="secondary" placeholder="Search documentation"  autocomplete="off">
        </mat-form-field>
      </form>
      <div class="list">
        <h4>📦 Orders</h4>
        <ng-container *ngFor="let doc of docs">
          <button mat-button class="ml-3 w-100" (click)="selectDoc(doc)" [attr.title]="doc.name">
            <span [innerHTML]="doc.name"></span>
          </button>
        </ng-container>

        <h4>🛠 Management</h4>
        <button mat-button class="ml-3 w-100">
          Customers
        </button>
        <button mat-button class="ml-3 w-100">
          Employees
        </button>
        <button mat-button class="ml-3 w-100">
          Stores
        </button>
        <h4>🏃‍♂️ Tasks</h4>
        <button mat-button class="ml-3 w-100">
          Ship from store
        </button>
        <button mat-button class="ml-3 w-100">
          Interbranch
        </button>
        <button mat-button class="ml-3 w-100">
          Cycle counts
        </button>
      </div>
    </div>
  </aside>


  <div class="d-flex h-100 scroll-container">
    <eva-markdown-html [markdown]="selectedDocument.markdown" (potentialServicesCallback)="potentialServicesCallback($event)">
    </eva-markdown-html>

    <div class="right-panel">
      <mat-form-field>
        <mat-select placeholder="Choose endpoint" [formControl]="endpointCtrl">
          <mat-option *ngFor="let services of relevantServices" [value]="services">
            {{services.Name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="currentTesterState">
        <eva-service-tester-info [testerInfo]="currentTesterState"></eva-service-tester-info>
        <eva-service-tester [testerState]="currentTesterState"></eva-service-tester>
      </ng-container>
    </div>
  </div>
</div>

<eva-tester-status-bar></eva-tester-status-bar>
