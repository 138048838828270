import { Component, OnInit, Input, forwardRef, HostListener } from '@angular/core';
import { GetAvailableServiceDetailsResponseTypeDefinition } from '../ui-editor/ui-editor.component';
import { FormBuilder, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Logger, ILoggable } from 'src/app/decorators/logger';
import { isEmpty, isNil } from 'lodash-es';

@Logger
@Component({
  selector: 'eva-ui-editor-object',
  templateUrl: './ui-editor-object.component.html',
  styleUrls: ['./ui-editor-object.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiEditorObjectComponent),
    multi: true
  }]
})
export class UiEditorObjectComponent implements OnInit, ILoggable, ControlValueAccessor {
  logger: Partial<Console>;

  @Input() field: GetAvailableServiceDetailsResponseTypeDefinition;

  form = this.fb.group({});

  registerOnTouchedCb: Function;

  @HostListener('blur')
  onBlur() {
    this.registerOnTouchedCb();
  }

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.logger.log('object', this.field);
    this.field.Fields.forEach( field => {
      this.form.addControl(field.Name, this.fb.control(null));
    });
  }

  writeValue(obj: any): void {
    if (isNil(obj) || isEmpty(obj)) {
      return;
    }

    this.form.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.registerOnTouchedCb = fn;
  }

}
