import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { GetAvailableServiceDetailsResponseTypeDefinition } from './ui-editor/ui-editor.component';

/** Determines if a given field is an array or not */
@Pipe({
  name: 'isObjectField'
})
export class IsObjectFieldPipe implements PipeTransform {

  transform(value: GetAvailableServiceDetailsResponseTypeDefinition): boolean {
    return !isEmpty(value.Fields);
  }

}
