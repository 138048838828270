import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { GetAvailableServiceDetailsResponseTypeDefinition } from './ui-editor/ui-editor.component';
import { TypingsParserService } from './typings-parser.service';
import { Logger, ILoggable } from 'src/app/decorators/logger';

/** Determines if a given field is an array or not */
@Logger
@Pipe({
  name: 'isEnumField'
})
export class IsEnumFieldPipe implements PipeTransform, ILoggable {
  logger: Partial<Console>;

  constructor(private $typingsParser: TypingsParserService) {}

  transform(value: GetAvailableServiceDetailsResponseTypeDefinition, typings: string): boolean {
    const fieldName = value.Type;

    const matchResults = this.$typingsParser.get('enum', fieldName, typings);

    const isEnumField = !isEmpty(matchResults);

    return isEnumField;
  }

}
