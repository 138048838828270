import { Injectable, NgZone } from '@angular/core';
import { getAvailableOpenIDConfigurations, store } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash';
import { UserManager, User } from 'oidc-client';
import { BehaviorSubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Logger } from 'src/app/decorators/logger';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * We will find providers with this user type in order to prevent login errors.
 * @see https://n6k.atlassian.net/browse/OPTR-11690
 */
const EMPLOYEE_USER_TYPE = 1;

@Logger
@Injectable()
export class OpenIdAuthService {
  public logger: Partial<Console>;

  public providers$: BehaviorSubject<EVA.Authentication.OpenID.AvailableOpenIDConfiguration[]> = new BehaviorSubject(
    [],
  );

  public openIdLoginSuccess$ = new Subject<Partial<EVA.Core.Login>>();

  private oidcClientUserManager: UserManager;

  private get redirect_uri(): string {
    const redirectUri = window.location.href;

    return redirectUri;
  }

  constructor(private zone: NgZone, private matSnackBar: MatSnackBar) { }

  public async initialise(url: string) {
    try {
      await this.configureOpenIdSettings();

      this.handlePotentialCallback(url);
    } catch (error) {
      this.logger.error('error calling initialise', error);
    }
  }

  public async handleDeepLinking(url: string) {
    try {
      const user = await this.oidcClientUserManager.signinCallback(url);
      this.openIdResponseCallback(user);
    } catch (error) {
      this.logger.error('Failed callback redirection for OpenID provider', error);
      this.matSnackBar.open('Error logging in with openID', null, { duration: 3000 });
    }
  }

  public async login(openIdProviderID: number) {
    try {
      this.setOpenIdProviderID(openIdProviderID);
      this.oidcClientUserManager.signinRedirect();
    } catch (error) {
      this.logger.error('Failed OpenID redirection', error);
    }
  }

  private async configureOpenIdSettings() {
    let openIdAdditionalProviders: EVA.Authentication.OpenID.AvailableOpenIDConfiguration[] = [];

    const [action, promise] = getAvailableOpenIDConfigurations.createFetchAction();

    store.dispatch(action);

    const response = await promise;

    openIdAdditionalProviders = response.Providers
      .filter(provider => Boolean(provider.UserType & EMPLOYEE_USER_TYPE))
      .sort((provider1, provider2) => {
        return provider1.Primary === provider2.Primary ? 0 : provider1.Primary ? -1 : 1;
      });

    this.providers$.next(openIdAdditionalProviders);
  }

  /** when the app is opened, maybe it was opened after the login flow. We will be checking this here */
  private async handlePotentialCallback(url: string) {
    try {
      const openIdProviderID = this.getOpenIdProviderID();

      if (isNil(openIdProviderID)) {
        return;
      }

      // If we don't have the id_token parameter, this is not a redirect
      // see https://n6k.atlassian.net/browse/OPTR-10909
      //
      if (!window.location.href?.includes('id_token')) {
        return;
      }

      const openIdProvider = this.providers$.value.find((provider) => provider.ID === openIdProviderID);
      this.oidcClientUserManager = new UserManager({
        authority: openIdProvider.BaseUrl,
        client_id: openIdProvider.ClientID,
        redirect_uri: this.redirect_uri,
        scope: 'openid profile email'
      });
      const user = await this.oidcClientUserManager.signinRedirectCallback(url);

      this.openIdResponseCallback(user);
    } catch (error) {
      this.logger.error('Failed callback redirection for OpenID provider', error);

      this.matSnackBar.open('Error logging in with openID', null, { duration: 3000 });
    }
  }

  private async openIdResponseCallback(user: User) {
    const openIdProviderID = this.getOpenIdProviderID();
    this.resetOpenIdProviderID();

    const loginData: Partial<EVA.Core.Login> = {
      AsEmployee: true,
      CustomAuthenticateData: {
        id_token: user.id_token,
        access_token: user.access_token,
        provider: openIdProviderID,
      },
      CustomAuthenticatorType: 'OpenID',
    };

    this.zone.run(() => {
      this.openIdLoginSuccess$.next(loginData);
    });

  }

  private setOpenIdProviderID(id: number) {
    const openIdProvider = this.providers$.value.find((provider) => provider.ID === id);

    this.oidcClientUserManager = new UserManager({
      authority: openIdProvider.BaseUrl,
      client_id: openIdProvider.ClientID,
      redirect_uri: this.redirect_uri,
      scope: 'openid profile email'
    });
    localStorage.setItem('OpenIdProviderID', id.toString());
  }

  private getOpenIdProviderID() {
    const id = localStorage.getItem('OpenIdProviderID');

    return isNil(id) ? null : Number(id);
  }

  private resetOpenIdProviderID() {
    localStorage.removeItem('OpenIdProviderID');
  }

}
