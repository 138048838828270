import { Component, forwardRef, Input, OnInit, HostListener } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ILoggable, Logger } from 'src/app/decorators/logger';
import { TypingsParserService } from '../typings-parser.service';
import { GetAvailableServiceDetailsResponseTypeDefinition, RootUiEditorProvider } from '../ui-editor/ui-editor.component';
import { first } from 'rxjs/operators';

interface EnumField {
  label: string;
  value: number;
}

@Logger
@Component({
  selector: 'eva-ui-editor-enum',
  templateUrl: './ui-editor-enum.component.html',
  styleUrls: ['./ui-editor-enum.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiEditorEnumComponent),
    multi: true
  }]
})
export class UiEditorEnumComponent implements OnInit, ILoggable, ControlValueAccessor {

  logger: Partial<Console>;

  @Input() field: GetAvailableServiceDetailsResponseTypeDefinition;

  enumFields: EnumField[] = [];

  /** Whether the user can select multiple fields or not */
  isBitFlagEnum = true;

  mainControl = this.fb.control(null);

  registerOnTouchedCb: Function;

  @HostListener('blur')
  onBlur() {
    this.registerOnTouchedCb();
  }

  constructor(private $typingsParser: TypingsParserService, private fb: FormBuilder, private rootUiEditorProvider: RootUiEditorProvider) { }

  async ngOnInit() {
    this.logger.time('enum');

    const typings = await this.rootUiEditorProvider.typings$.pipe(
      first()
    ).toPromise();

    const matchResults = this.$typingsParser.get('enum', this.field.Type, typings);

    const enumLines = matchResults[0].split('\n');

    // Get rid of first line, which is the opening bracket
    //
    enumLines.splice(0, 1);
    // Getting rid of the last line
    //
    enumLines.splice(enumLines.length - 1, 1);

    const enumFields: EnumField[] = enumLines
      .map( enumLine => enumLine.replace(',', ''))
      .map( enumLine => {
        const [label, value] = enumLine.split('=');

        const enumField: EnumField = {
          label: label.split(' ').join(''),
          value: + value
        };

        return enumField;
      });

    this.enumFields = enumFields;

    this.logger.timeEnd('enum');

    // If the first two elements double in size, it means this is a bit flag enum
    //
    this.isBitFlagEnum = this.enumFields
      .map( enumField => enumField.value)
      // 2 comes after 1, and will not be indicative that this is a bitflag enum as some normal enums have the 1,2,3,4 order
      //
      .filter( enumFieldValue => enumFieldValue !== 1 )
      .some((enumFieldValue, index, enumFieldsArray) => {
      return enumFieldsArray[index + 1] === enumFieldValue * 2;
    });
  }

  writeValue(value: number[]|number): void {
    this.mainControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.mainControl.valueChanges.subscribe((value: number[]|number) => {
      Array.isArray(value) ? fn(value.reduce((a, b) => a + b, 0)) : fn(value);
    });
  }

  registerOnTouched(fn: any): void {
    this.registerOnTouchedCb = fn;
  }
}
