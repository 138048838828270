import { NgModule} from '@angular/core';
import { ImageDirective } from './directive/eva-image.directive';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    ImageDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [ImageDirective]
})
export class DirectivesModule { }
