import { Component, OnInit, OnDestroy } from '@angular/core';
import { login } from '@springtree/eva-sdk-redux';
import isNotNil from 'src/app/shared/operators/is-not-nil';
import { filter, takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'eva-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit, OnDestroy {

  private stop$ = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<LoginModalComponent>) { }

  ngOnInit() {
    login.getResponse$().pipe(
      isNotNil(),
      filter( response => response.Authentication === 2),
      takeUntil(this.stop$)
    ).subscribe(() => {
      this.dialogRef.close();
    });
  }

  ngOnDestroy() {
    this.stop$.next();
  }
}
