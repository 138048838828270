import { Component, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isEmpty } from 'lodash-es';
import { tap } from 'rxjs/operators';
import { ILoggable, Logger } from 'src/app/decorators/logger';
import { GetAvailableServiceDetailsResponseTypeDefinition } from '../ui-editor/ui-editor.component';

@Logger
@Component({
  selector: 'eva-ui-editor-array',
  templateUrl: './ui-editor-array.component.html',
  styleUrls: ['./ui-editor-array.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiEditorArrayComponent),
    multi: true
  }]
})
export class UiEditorArrayComponent implements OnInit, ILoggable, ControlValueAccessor {
  logger: Partial<Console>;

  @Input() field: GetAvailableServiceDetailsResponseTypeDefinition;

  fields: GetAvailableServiceDetailsResponseTypeDefinition[] = [];

  mainControl = this.fb.group({
    elements: this.fb.array([])
  });

  registerOnTouchedCb: Function;

  @HostListener('blur')
  onBlur() {
    this.registerOnTouchedCb();
  }

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.logger.log(this.field);
    this.addElement();
  }

  addElement() {
    /** Generic fields have their child fields one level deeper than non generic fields */
    const isGenericType = this.field.Type.includes('<') && this.field.Type.includes('>');

    if (!isEmpty(this.field.Fields)) {
      if (isGenericType) {
        this.logger.log('Generic field, go one level deep to find relevant fields');
        this.fields.push(this.field.Fields[0]);
      } else {
        this.logger.log('Non generic field take root fields');

        this.fields.push({
          ...this.field,
          Fields: this.field.Fields,
          Type: `${this.field.Name}Object`,
          Name: `${this.field.Name} Element`
        });
      }
    } else {
      this.fields.push({
        ...this.field,
        Type: this.field.Type.split('[]')[0]
      });
    }

    (this.mainControl.get('elements') as FormArray).push(
      this.fb.control(null)
    );
  }

  removeElement = (index: number) => {
    this.fields.splice(index, 1);

    (this.mainControl.get('elements') as FormArray).removeAt(index);
  }

  writeValue(arrValue: any[]): void {
    if (Array.isArray(arrValue)) {
      this.mainControl.get('elements').setValue(arrValue);
    }
  }

  registerOnChange(fn: any): void {
    this.mainControl.valueChanges.pipe(
      tap(value => this.logger.log('editor array change', value.elements))
    ).subscribe(value => fn(value.elements));
  }

  registerOnTouched(fn: any): void {
    this.registerOnTouchedCb = fn;
  }
}
