import { AfterViewInit, Component, forwardRef, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNil } from 'lodash-es';
import { IExtendedGetAvailableServiceDetailsResponseFieldTypeDefinition, RootUiEditorProvider } from '../ui-editor/ui-editor.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'eva-ui-editor-element',
  templateUrl: './ui-editor-element.component.html',
  styleUrls: ['./ui-editor-element.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiEditorElementComponent),
    multi: true
  }]
})
export class UiEditorElementComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  private stop$ = new Subject<void>();

  @Input() field: IExtendedGetAvailableServiceDetailsResponseFieldTypeDefinition;

  @Input()
  @HostBinding('class.is-array')
  isArray = false;

  @Input()
  arrayIndex: number;

  @Input()
  onDeleteButtonPress: Function;

  @Input()
  isRootElement: boolean = true;

  unknownTypeControl = this.fb.control(null);

  unknownTypeMockField: IExtendedGetAvailableServiceDetailsResponseFieldTypeDefinition;

  typings$ = this.rootUiEditorProvider.typings$;

  mainControl = new FormControl(undefined);

  registerOnTouchedCb: Function;

  @HostListener('blur')
  onBlur() {
    this.registerOnTouchedCb();
  }

  constructor(private fb: FormBuilder, private rootUiEditorProvider: RootUiEditorProvider) {}

  ngOnInit() {
    this.unknownTypeControl.valueChanges.pipe(takeUntil(this.stop$)).subscribe((value) => this.setUnknownTypeMockField(value));
  }

  ngAfterViewInit() {
    if (!isNil(this.field) && isNil(this.unknownTypeControl.value)) {
      let castedType = this.field.Type;
      if (castedType.includes('Int')) {
        castedType = 'Int32';
      }

      this.unknownTypeControl.setValue(castedType, { emitEvent: false });
      this.setUnknownTypeMockField(castedType);
    }
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  writeValue(newValue: any): void {
    if (isNil(newValue)) {
      return;
    }
    this.mainControl.setValue(newValue);
  }

  registerOnChange(fn: any): void {
    this.mainControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.registerOnTouchedCb = fn;
  }

  setUnknownTypeMockField(type: any) {
    this.unknownTypeMockField = {
      ...this.field,
      Type: type
    };

    if (type === 'Dictionary' ) {
      this.unknownTypeMockField.Fields = [{
        Fields: [],
        Description: '',
        Name: '',
        Namespace: '',
        Optional: true,
        Type: '',
        Deprecation: null
      }, {
        Name: '',
        Fields: [],
        Description: '',
        Namespace: '',
        Optional: true,
        Type: '',
        Deprecation: null
      }];
    }
  }

}
