import { Injectable } from '@angular/core';
import { EndPointUrlService } from './end-point-url.service';
import { ApplicationsService } from './applications.service';
import { get } from 'lodash-es';

interface IUserNameCache {
  [endpoint: string]: string;
}

/**
 * This service will be responsible for managing a user name cache which will be a key value pair structure
 * where the key is the endpoint & application and the value is the last username used to login into said endpoint
 */
@Injectable()
export class UsernameCacheService {

  private _cachedUserName: IUserNameCache;

  private get cachedUserName(): IUserNameCache {
    return this._cachedUserName || JSON.parse(localStorage.getItem('cachedUserName'));
  }

  private set cachedUserName(cachedUserName: IUserNameCache) {
    this._cachedUserName = cachedUserName;

    localStorage.setItem('cachedUserName', JSON.stringify(cachedUserName));
  }

  constructor(
    private $endpointUrl: EndPointUrlService,
    private $application: ApplicationsService
  ) { }

  public set(username: string) {
    const key = this.getKey();

    const newCachedUserName = this.cachedUserName || {};

    newCachedUserName[key] = username;

    this.cachedUserName = newCachedUserName;
  }

  public get(): string|null {
    const key: string = this.getKey();

    const cachedUserName = (this.cachedUserName || {} as IUserNameCache)[key];

    return cachedUserName;
  }

  /**
   * Gets the current key for the current end point and application
   */
  private getKey(): string {
    /** The key will be a combination of endpoint and application */
    const key = `${this.$endpointUrl.endPointUrl}-${get(this.$application.getSelected(), 'ID')}`;

    return key;
  }
}
