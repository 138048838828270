import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { settings } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash-es';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform, OnDestroy {

  private stop$: Subject<void> = new Subject<void>();
  private storedTransformedDate: string;
  private storedInitialDate: string;
  private storedFormat: string;
  private storedTimeZone: string;
  private defaultLanguage = 'en-us';

  constructor(private cd: ChangeDetectorRef) {
    settings.changes$.pipe(
      takeUntil(this.stop$),
      filter(change => change.name === 'language'),
    ).subscribe((change) => {
      this.transformDate(this.storedInitialDate, this.defineFormat(this.storedFormat), change.new, this.storedTimeZone);
    });
  }

  transformDate(date: any, format: string, language: string = this.defaultLanguage, timezone?: string) {
    let formattedDate = '';
    if (!isNil(timezone) && timezone !== '') {
      formattedDate = formatDate(date, format, language, timezone);
    } else {
      formattedDate = formatDate(date, format, language);
    }
    this.storedInitialDate = date;
    this.storedTransformedDate = formattedDate;
    this.cd.markForCheck();
  }

  transform(value: any, format: string = 'mediumDate', timezone?: string): any {
    if (this.storedInitialDate === value) {
      if (this.storedTimeZone) {
        return `${this.storedTransformedDate} ${this.storedTimeZone}`;
      }
      return this.storedTransformedDate;
    }  if (value == null || value === '') {
      return null;
    }
    this.storedFormat = format;
    this.storedTimeZone = timezone;
    this.transformDate(value, this.defineFormat(format), settings.language, timezone);
    if (this.storedTransformedDate) {
      if (this.storedTimeZone) {
        return `${this.storedTransformedDate} ${this.storedTimeZone}`;
      }
      return this.storedTransformedDate;
    }
  }

  defineFormat(format: string) {
    if (format === 'mediumDateTime') {
      return 'd MMM y H:mm';
    }
    return format;
  }

  ngOnDestroy() {
    this.stop$.next();
  }

}
