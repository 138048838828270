import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'img[eva-image]',
})
export class ImageDirective {

  @HostBinding('class.loaded')
  loaded = false;

  @HostListener('load')
  onLoad() {
    this.loaded = true;
  }
}
