import { Component, OnInit, Input, forwardRef, HostListener } from '@angular/core';
import { GetAvailableServiceDetailsResponseTypeDefinition } from '../ui-editor/ui-editor.component';
import { Logger, ILoggable } from 'src/app/decorators/logger';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormBuilder, FormArray } from '@angular/forms';
import { map } from 'rxjs/operators';
import { isNil } from 'lodash-es';

interface FormValue {
  elements: Array<{
    key: string;
    value: any
  }>;
}

interface DictionaryValue {
  [key: string]: any;
}

@Logger
@Component({
  selector: 'eva-ui-editor-dictionary',
  templateUrl: './ui-editor-dictionary.component.html',
  styleUrls: ['./ui-editor-dictionary.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiEditorDictionaryComponent),
    multi: true
  }]
})
export class UiEditorDictionaryComponent implements OnInit, ILoggable, ControlValueAccessor {
  logger: Partial<Console>;

  @Input() field: GetAvailableServiceDetailsResponseTypeDefinition;

  // TO:DO find the fields in the root field structure. Some requests like the SearchProducts one have nested ditionaries,
  // an example is the AggregationOptions
  //
  fields: GetAvailableServiceDetailsResponseTypeDefinition[] = [];

  registerOnTouchedCb: Function;

  form = this.fb.group({
    elements: this.fb.array([])
  });

  constructor(private fb: FormBuilder) {}

  @HostListener('blur')
  onBlur() {
    this.registerOnTouchedCb();
  }

  ngOnInit() {
    this.logger.log(this.field);
    this.addElement();
  }


  addElement() {
    const [, dictionaryValue] = this.field.Fields;
    this.logger.log('dictonary value', dictionaryValue);
    this.fields.push(dictionaryValue);

    (this.form.get('elements') as FormArray).push(
      this.fb.group({
        key: [],
        value: []
      })
    );
  }

  writeValue(newDictionaryValue: DictionaryValue): void {

    if (isNil(newDictionaryValue)) {
      return;
    }

    const formValue: FormValue = {
      elements: Object.entries(newDictionaryValue).map( ([dictionaryElemenetKey, dictionaryElementValue]) => {
        return {
          key: dictionaryElemenetKey,
          value: dictionaryElementValue,
        };
      })
    };

    this.form.setValue(formValue);
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.pipe(
      map( (formValue: FormValue) => {
        const dictionaryValue: DictionaryValue = {};

        formValue.elements.forEach(({ key, value }) => dictionaryValue[key] = value);

        return dictionaryValue;
      })
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.registerOnTouchedCb = fn;
  }
}
